const containerClose = {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      visible: this.value
    }
  },
  watch: {
    value (val) {
      this.visible = val
    }
  },
  methods: {
    closeHandle () {
      this.visible = false
      this.$emit('input', this.visible)
      this.$emit('close')
    },
    close () {
      this.visible = false
      this.$emit('input', this.visible)
      this.$emit('close')
    }
  }
}

export default containerClose