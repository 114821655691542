<template>
  <div>
    <a-card :headStyle="{paddingLeft:0}" :bordered="false" style="width:100%"
            :tab-list="tabListNoTitle" :active-tab-key="noTitleKey"
            @tabChange="key => onTabChange(key, 'noTitleKey')">
      <div v-if="noTitleKey === 'FORECAST'">
        <a-table size="middle"
                 :rowKey="(record) => record.id"
                 @change="forecastTableChange"
                 :pagination="forecastPagination"
                 :columns="forecastColumns"
                 :dataSource="forecastTableData">
          <template slot="_index" slot-scope="text, record, index">
            {{ forecastSearchParams.number * forecastSearchParams.size + index + 1 }}
          </template>
          <template slot="days" slot-scope="text, record">
            <span>{{record.nextTime|calDays}}</span>
          </template>
          <template slot="action" slot-scope="text, record">
            <a @click.stop="handleView(record)">报抽历史</a>
            <a-divider type="vertical"/>
            <a @click.stop="handleReport(record)">登记</a>
          </template>
        </a-table>
        <a-drawer title="集污点详细信息"
                  placement="right"
                  :closable="true"
                  @close="detailClose"
                  width="1200px"
                  :visible="detailDrawer"
                  :mask="false">
          <spot-detail ref="viewRef"></spot-detail>
        </a-drawer>
        <a-modal title="报抽登记"
                 :visible="addModal"
                 width="1200px"
                 :maskClosable="false"
                 centered
                 @ok="handleAddOk"
                 @cancel="closeAddModal">
          <register-form ref="addRef" type="add" @addSuccess="addSuccess" @addError="addError"></register-form>
        </a-modal>
      </div>
      <div v-else>
        <a-table size="middle"
                 :rowKey="(record) => record.id"
                 @change="tableChange"
                 :pagination="pagination"
                 :columns="columns"
                 :dataSource="tableData">
          <template slot="_index" slot-scope="text, record, index">
            {{ searchParams.number * searchParams.size + index + 1 }}
          </template>
          <template slot="name" slot-scope="text, record">
            <a-icon v-if="record.openId" style="color:#2ba245" type="wechat" theme="filled"/>&nbsp;<a-icon
            v-if="record.ifUrgent" style="color:#ff644e" type="fire" theme="filled"/>&nbsp;&nbsp;&nbsp;{{record.spot?record.spot.name:''}}
          </template>
          <template slot="type" slot-scope="text, record">
            <div v-if="record.type==='TEL'">
              电话
            </div>
            <div v-if="record.type==='WECHAT'">
              微信
            </div>
            <div v-if="record.type==='APP'">
              APP
            </div>
            <div v-if="record.type==='OTHER'">
              自主任务
            </div>
          </template>
          <template slot="fromAddress" slot-scope="text, record">
            <a-tooltip>
              <template slot="title">
                <div v-if="record.spot && record.spot.houseNo">
                  {{ record.fromAddress+ '[' +record.spot.houseNo+']'}}
                </div>
                <div v-else> {{ record.fromAddress}}</div>
              </template>
              <div v-if="record.spot && record.spot.houseNo">{{record.fromAddress+ '[' + record.spot.houseNo+']'}}
              </div>
              <div v-else> {{ record.fromAddress}}</div>
            </a-tooltip>
          </template>
          <span slot="action" slot-scope="text, record">
          <template>
            <a-button type="primary" shape="circle" icon="carry-out" size="small"
                      v-if="(record.bindStatus ==='OPTIONAL' || record.bindStatus ==='NOTOPEN')"
                      @click.stop="dispatch(record)"/>
            <a-divider type="vertical" v-if="((record.bindStatus ==='OPTIONAL' || record.bindStatus ==='NOTOPEN') && (record.handleStatus==='SCHEDULED' || record.handleStatus==='PROCESSING'))"/>
            <a-button type="default" shape="circle" icon="edit" size="small" style="color: #46c190"
                      v-if="(record.handleStatus==='SCHEDULED' || record.handleStatus==='PROCESSING') && (record.bindStatus ==='OPTIONAL' || record.bindStatus ==='NOTOPEN'|| record.bindStatus ==='CHECKED')"
                      @click.stop="handleEdit(record.id)"/>
          </template>
        </span>
        </a-table>
      </div>
    </a-card>
    <a-modal width="1200px" title="修改上报内容"
             :visible="editModal"
             :maskClosable="false"
             @ok="handleEditOk"
             @cancel="closeEditModal">
      <register-form ref="editRef" type="edit" @editSuccess="editSuccess" @editError="editError"></register-form>
    </a-modal>
    <a-modal width="800px"
             title="调度指派"
             :visible="dispatchModal"
             @ok="dispatchOk"
             @cancel="closeDispatchModal">
      <DispatchAssignForm ref="dispatchRef" type="dispatch" @dispatchSuccess="dispatchSuccess"
                          @dispatchError="dispatchError"></DispatchAssignForm>
    </a-modal>
  </div>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import moment from 'moment'
  import entityCRUD from '../../../common/mixins/entityCRUD'
  import DispatchAssignForm from '../../reportrecord/report/DispatchForm'
  import RegisterForm from '../../reportrecord/report/Form'
  import { columns } from '../../forecast/common/common'
  import SpotDetail from '../../gatherspot/Detail'

  export default {
    name: 'ReportRecordStatistics',
    mixins: [entityCRUD],
    components: { DispatchAssignForm, RegisterForm, SpotDetail },
    props: {},
    filters: {
      calDays (time) {
        return moment(time).diff(moment(), 'days')
      }
    },
    data () {
      return {
        noTitleKey: 'NOTOPEN',
        tabListNoTitle: [
          {
            key: 'NOTOPEN',
            tab: '未审核'
          },
          {
            key: 'OPTIONAL',
            tab: '待接单'
          },
          {
            key: 'CHECKED',
            tab: '已接单'
          },
          {
            key: 'DOING',
            tab: '清运中'
          },
          {
            key: 'FORECAST',
            tab: '清运预测'
          }
        ],
        entityBaseUrl: SERVICE_URLS.csgz.reportRecord,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          bindStatus: 'NOTOPEN'
        },
        tableData: [],
        initColumns: [
          {
            title: '序号',
            width: 45,
            align: 'center',
            scopedSlots: { customRender: '_index' }
          },
          {
            title: '集污点编号',
            align: 'center',
            width: 110,
            dataIndex: 'spot.no'
          },
          {
            title: '集污点名称',
            align: 'left',
            width: 150,
            dataIndex: 'spot.name',
            scopedSlots: { customRender: 'name' }
          },
          {
            title: '来电电话',
            align: 'center',
            width: 110,
            ellipsis: true,
            dataIndex: 'fromPhone'
          },
          {
            title: '区域名称',
            align: 'left',
            width: 135,
            ellipsis: true,
            dataIndex: 'spot.areaName'
          },
          {
            title: '上报地址',
            align: 'left',
            width: 230,
            ellipsis: true,
            dataIndex: 'fromAddress',
            scopedSlots: { customRender: 'fromAddress' }
          },
          {
            title: '上报类型',
            align: 'center',
            width: 75,
            dataIndex: 'type',
            scopedSlots: { customRender: 'type' }
          },
          {
            title: '清运司机',
            align: 'center',
            width: 75,
            dataIndex: 'driver.name'
          },
          {
            title: '上报时间',
            align: 'center',
            width: 160,
            dataIndex: 'fromTime'
          },
          {
            title: '最晚清运时间',
            align: 'center',
            width: 100,
            dataIndex: 'latestDate'
          },
          {
            title: '操作',
            align: 'center',
            width: 100,
            scopedSlots: { customRender: 'action' }
          }
        ],
        dispatchModal: false,
        forecastSearchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: ''
        },
        forecastColumns: columns(),
        forecastPagination: {
          current: 1,
          total: 1,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}条记录`
        },
        forecastTableData: []
      }
    },
    created () {
      this.search()
    },
    methods: {
      onTabChange (key, type) {
        this[type] = key
        if (key === 'FORECAST') {
          this.loadForecastTableData()
        } else {
          this.searchParams.bindStatus = key
        }
        this.search()
      },
      /*调度指派*/
      dispatch (record) {
        this.dispatchModal = true
        this.$nextTick(() => {
          this.$refs['dispatchRef'].loadData(record.id)
        })
      },
      dispatchOk () {
        this.$refs['dispatchRef'].submitEditDispatch()
      },
      closeDispatchModal () {
        this.dispatchModal = false
      },
      dispatchSuccess () {
        this.dispatchModal = false
        this.search()
      },
      dispatchError () {
        this.dispatchModal = false
      },
      //清运预测
      loadForecastTableData () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.loopForecastApi.search,
          noTips: true,
          loading: 'tableLoading',
          data: this.forecastSearchParams,
          success: (data) => {
            this.forecastTableData = data.body.content
            this.forecastPagination.total = data.body.totalElements
            this.forecastPagination.current = data.body.number + 1
            this.forecastPagination.pageSize = data.body.size
            this.forecastSearchParams.size = data.body.size
            this.forecastSearchParams.number = data.body.number
          }
        })
      },
      forecastTableChange (pagination, filters, sorter) {
        this.forecastSearchParams.number = pagination.current - 1
        this.forecastSearchParams.size = pagination.pageSize
        this.loadForecastTableData()
      },
      handleView (record) {
        this.detailDrawer = true
        this.$nextTick(() => {
          //重置报抽查询条件按照编号查询
          this.$refs.viewRef.resetData()
          this.$refs.viewRef.loadData(record.gatherSpot.id)
        })
      },
      handleReport (record) {
        this.addModal = true
        this.$nextTick(() => {
          this.$refs.addRef.findGatherSpot(record.gatherSpot)
        })
      }
    }
  }
</script>