// 车辆任务历史列表表头
export const historyColumns = () => {
  return [
    {
      title: '#',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '执行司机',
      width: 120,
      align: 'center',
      dataIndex: 'driver.name'
    },
    {
      title: '执行时间',
      width: 360,
      align: 'left',
      scopedSlots: { customRender: 'executeTime' }
    },
    {
      title: '车辆任务',
      width: 260,
      align: 'left',
      scopedSlots: { customRender: 'reportNames' }
    },
    {
      title: '执行公里',
      width: 100,
      align: 'center',
      dataIndex: 'mileage',
      scopedSlots: { customRender: 'mileage' }
    },
    {
      title: '清运吨数',
      width: 100,
      align: 'center',
      dataIndex: 'carInfo.volume'
    },
    {
      title: '清运时长',
      width: 100,
      align: 'center',
      dataIndex: 'durationStr'
    },
    {
      title: '排放污水厂',
      width: 140,
      align: 'center',
      dataIndex: 'destination'
    },
    {
      title: '执行状态',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'status' }
    },
  ]
}
