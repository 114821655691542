export const carInfoFormVO = function () {
  return {
    carInfo: {
      department: {
        id: null,
        name: ''
      },
      objectId: '', //设备ID
      platNumber: '', //车牌号
      fullName: '', //名称
      macId: '', //设备号
      macName: '', //设备型号
      blockDate: '', //到期时间
      buyDate: '', //购买时间
      volume: '', //车辆容积
      sim: '', //sim卡号
      sorter: '',
      useType: '',
      status: 'FREE',
      frameNumber: ''
    },
    record: {
      department: {
        id: null,
        name: ''
      },
      carInfo: {
        id: null,
        fullName: ''
      },
      managerUser: {
        id: null,
        name: ''
      },
      executeUser: {
        id: null,
        name: ''
      },
      planStartTime: '', //pc端计划开始时间
      planEndTime: '', //计划结束时间
      summary: '', //行车记录概要
      mileage: '', //行车公里
      status: '',
      type: 'GIVEN'
    },
    loopRecord: {
      department: {
        id: null,
        name: ''
      },
      carInfo: {
        id: null,
        fullName: ''
      },
      driver: {
        id: null,
        name: ''
      },
      recordIds: '', //对应的车辆任务IDS
      recordNames: '', //对应的车辆任务名称
      startTime: '', //开始时间
      endTime: '', //结束时间
      summary: '', //清运概述
      mileage: '', //执行公里
      duration: '', //清运时长
      durationStr: '', //清运时长
      status: '' //执行状态
    },
    recordTrail: {
      id: null,
      position: null
    },
    imageIds: [],
    images: []
  }
}