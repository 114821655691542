<template>
  <right-form-container :title="baseAreaItem.name" v-model="visible" @close="close">
    <a-spin :spinning="spinShow" :delay="200">
      <div v-malihu-scrollbar class="mCustomScrollbar" style="overflow-y: auto;" :style="{ 'max-height': bodyHeight + 'px' }">
        <div class="p-12">
          <a-form layout="inline">
         <!--   <a-row :gutter="20">
              <a-col :span="12">
                <a-form-item label="编号">
                  <span>{{ baseAreaItem.code }}</span>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="名称">
                  <span>{{ baseAreaItem.name }}</span>
                </a-form-item>
              </a-col>
            </a-row>-->
            <a-row :gutter="20">
              <a-col :span="24">
                  <span>{{ baseAreaItem.description }}</span>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="p-12 p-t-12">
          <a-row :gutter="16">
            <a-col :span="12">
              <metrics value="集污池"><span slot="title">{{ areaTotalItem.JIWUCHI }}</span></metrics>
            </a-col>
            <a-col :span="12">
              <metrics value="三格化粪池"><span slot="title">{{ areaTotalItem.SANGE }} </span></metrics>
            </a-col>
          </a-row>
          <a-row :gutter="16" class="m-t-12">
            <a-col :span="12">
              <metrics value="单翁"><span slot="title">{{ areaTotalItem.DANWENG }}</span></metrics>
            </a-col>
            <a-col :span="12">
              <metrics value="总数"><span slot="title">{{ areaTotalItem.sumTotal }}</span></metrics>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-spin>
  </right-form-container>
</template>
<script>
  import moment from 'moment'
  import SERVICE_URLS from '@/api/service.url'
  import { mapState } from 'vuex'
  import containerClose from '../mixins/container-close'
  import RightFormContainer from './RightFormContainer'
  import Metrics from './Metrics'

  export default {
    name: 'AreaDetail',
    components: { RightFormContainer, Metrics },
    mixins: [containerClose],
    data () {
      return {
        spinShow: false,
        areaTotalItem: { DANWENG: 0, JIWUCHI: 0, SANGE: 0, sumTotal: 0 },
        baseAreaItem: {}
      }
    },
    computed: {
      ...mapState({
        bodyHeight: (state) => {
          return state.app.bodyHeight - 200
        }
      })
    },
    methods: {
      moment,
      loadAreaInfo (item) {
        this.baseAreaItem = item
        this.areaTotalItem = { DANWENG: 0, JIWUCHI: 0, SANGE: 0, sumTotal: 0 }
        this.$http(this, {
          url: SERVICE_URLS.csgz.gatherSpot.areaTotal,
          data: {
            areaNo: item.code
          },
          noTips: 'true',
          success: (data) => {
            let areaTotal = data.body
            let sumTotal = 0
            areaTotal.forEach((x) => {
               this.areaTotalItem[x.type] = x.total
               sumTotal =  sumTotal +  x.total
            })
            this.areaTotalItem.sumTotal = sumTotal
          }
        })
      },
      close () {
        this.visible = false
        this.$emit('input', this.visible)
        this.$emit('close')
        this.map = null
      }
    }
  }
</script>
<style scoped>
  .banner-img {
    width: 100%;
    position: relative;
  }

  .image-top-btn {
    font-size: 12px;
    color: #ffffff;
    background-color: rgb(76, 69, 69);
    border: none;
    border-radius: 2px;
    outline: none;
    text-align: center;
  }

  .left_btn {
    position: absolute;
    left: 32px;
    top: 50px;
  }

  .right_btn {
    position: absolute;
    right: 35px;
    top: 50px;
  }
</style>
