<template>
  <a-card :bordered="false">
    <div class="fs-search-box">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-form-item label="车辆名称" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-select disabled
                      optionFilterProp="label"
                      v-model="searchParams.carInfoId"
                      placeholder="请选择"
                      @change="search">
              <a-select-option v-for="item in carList" :key="item.id" :value="item.id" :label="item.name">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="清运日期" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-range-picker style="width: 260px;"
                            format="YYYY-MM-DD" v-model="dateSearch"
                            @change="handleModalPickerOk"></a-range-picker>
          </a-form-item>
          <a-form-item class="f-r m-r-none">
            <a-button type="primary" @click="search" icon="search">查询</a-button>
            <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
          </a-form-item>
          <a-row :gutter="[16,16]">
            <a-col :sm="24" :md="12" :lg="8" :xl="4">
              <a-card>
                <a-statistic
                  title="清运次数"
                  :value="statisticsData.records+' 次' || '0 次'"
                  :valueStyle="valueStyle"
                  style="text-align: center;">
                </a-statistic>
              </a-card>
            </a-col>
            <a-col :sm="24" :md="12" :lg="8" :xl="4">
              <a-card>
                <a-statistic
                  title="清运公里"
                  :value="statisticsData.mileages?statisticsData.mileages.toFixed(2)+' km' : '0 km'"
                  :valueStyle="valueStyle"
                  style="text-align: center;">
                </a-statistic>
              </a-card>
            </a-col>
            <a-col :sm="24" :md="12" :lg="8" :xl="4">
              <a-card>
                <a-statistic
                  title="清运时长"
                  :value="statisticsData.durations||'0'"
                  :valueStyle="valueStyle"
                  style="text-align: center;">
                </a-statistic>
              </a-card>
            </a-col>
            <a-col :sm="24" :md="12" :lg="8" :xl="4">
              <a-card>
                <a-statistic title="当前状态" v-if="statisticsData.carInfo.offline"
                             :value="statisticsData.carInfo.offline === '1'? '静止': statisticsData.carInfo.offline === '2'? '行驶': '离线'"
                             :valueStyle="valueStyle"
                             style="text-align: center;">
                </a-statistic>
              </a-card>
            </a-col>
            <a-col :sm="24" :md="12" :lg="8" :xl="4">
              <a-card>
                <a-statistic
                  title="车辆牌号"
                  :value="statisticsData.carInfo.platNumber || '-'"
                  :valueStyle="valueStyle"
                  style="text-align: center;">
                </a-statistic>
              </a-card>
            </a-col>
            <a-col :sm="24" :md="12" :lg="8" :xl="4">
              <a-card>
                <a-statistic
                  title="当前速度"
                  :value="statisticsData.carInfo.speed!==null?statisticsData.carInfo.speed+' km/h':'0 km/h' || '0 km/h'"
                  :valueStyle="valueStyle"
                  style="text-align: center;">
                </a-statistic>
              </a-card>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </div>
    <a-table
      size="middle"
      :columns="columns"
      :dataSource="tableData"
      :loading="tableLoading"
      :pagination="pagination"
      @change="tableChange"
      :customRow="rowClick"
      :rowKey="(record) => record.id"
      :scroll="{x:1350}">
      <template slot="_index" slot-scope="text, record, index">
        {{ searchParams.number * searchParams.size + index + 1 }}
      </template>
      <template slot="reportNames" slot-scope="text, record">
        <a-tag v-for="(item,index) in record.reportNames" :key="index" color="#E9967A" style="margin: 3px;">{{item}}</a-tag>
      </template>
      <template slot="status" slot-scope="text, record">
        <a-tag v-if="record.status === 'NOT'">未开始</a-tag>
        <a-tag v-if="record.status === 'DOING'" color="#87d068">进行中</a-tag>
        <a-tag v-if="record.status === 'COMPLETED'" color="#108ee9">已完成</a-tag>
      </template>
      <template slot="executeTime" slot-scope="text, record">
        <span>{{ record.startTime }} ~ {{ record.endTime }}</span>
      </template>
      <template slot="mileage" slot-scope="text, record">
        <span>{{ record.mileage }} | {{ record.modifyMileage }}</span>
      </template>
    </a-table>
    <!--车辆任务详情Model-->
    <a-modal
      :title="platNumber"
      :width="1400"
      :footer="null"
      :visible="recordDetailModal"
      @cancel="closeRecordDetailModal">
      <loop-record-detail ref="loopRecordDetailRef"></loop-record-detail>
    </a-modal>
  </a-card>
</template>

<script>
  import entityCRUD from '../../../common/mixins/entityCRUD'
  import SERVICE_URLS from '@/api/service.url'
  import { historyColumns } from './common/historyCommon'
  import LoopRecordDetail from '../../reportrecord/looprecord/Detail'

  export default {
    components: { LoopRecordDetail },
    mixins: [entityCRUD],
    name: 'HistoryList',
    data () {
      return {
        valueStyle: {
          color: '',
          textAlign: 'center'
        },
        entityBaseUrl: SERVICE_URLS.csgz.carLoopRecordApi,
        initColumns: historyColumns(),
        labelCol: { span: 7 },
        wrapperCol: { span: 17 },
        carList: [],
        searchParams: {
          page: '0',
          size: '10',
          direction: '',
          order: '',
          carInfoId: '',
          startTime: '',
          endTime: ''
        },
        dateSearch: null,
        statisticsData: {}, //车辆统计信息
        recordDetailModal: false,
        platNumber: ''
      }
    },
    mounted () {
      this.loadCarInfoList()
    },
    methods: {
      handleModalPickerOk (value) {
        if (value.length > 0) {
          this.searchParams.startTime = value[0].format('YYYY-MM-DD')
          this.searchParams.endTime = value[1].format('YYYY-MM-DD')
        } else {
          this.searchParams.startTime = ''
          this.searchParams.endTime = ''
        }
        this.search()
        this.loadCarStatistics()
      },
      loadCarInfoList () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.carInfoApi.select,
          noTips: true,
          success: (data) => {
            this.carList = data.body
          }
        })
      },
      loadCarStatistics () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.carInfoApi.statistics,
          data: {
            carInfo: this.searchParams.carInfoId,
            startTime: this.searchParams.startTime,
            endTime: this.searchParams.endTime
          },
          noTips: true,
          success: (data) => {
            this.statisticsData = data.body
          }
        })
      },
      searchRecord (id) {
        this.searchParams.carInfoId = id
        this.search()
        this.loadCarStatistics()
      },
      rowClick (record) {
        return {
          on: {
            click: () => {
              this.platNumber = '车牌号：' + record.carInfo.platNumber
              this.recordDetailModal = true
              this.$nextTick(() => {
                this.$refs.loopRecordDetailRef.loadData(record.id)
              })
            }
          }
        }
      },
      closeRecordDetailModal () {
        this.recordDetailModal = false
      },
      //抽屉关闭后清理选择日期
      resetData () {
        this.dateSearch = null
        this.searchParams.startTime = ''
        this.searchParams.endTime = ''
      },
      resetForm () {
        this.dateSearch = null
        this.searchParams.startTime = ''
        this.searchParams.endTime = ''
        this.search()
        this.loadCarStatistics()
      }
    }
  }
</script>
<style lang="less">
  .xunjian-history-list {

    .container-main {
      width: 100%;
      display: flex;
      height: 100%;

      .container-left {
        border-right: 1px solid #e8e8e8;
        min-width: 324px;
      }

      .container-right {
        flex: 1 1;
        overflow-x: auto;
      }
    }
  }

</style>
<!--<style lang="less" scoped>
  .account-settings-info-main {
    width: 100%;
    display: flex;
    height: 100%;
    overflow: auto;

    &.mobile {
      display: block;

      .account-settings-info-left {
        border-right: unset;
        border-bottom: 1px solid #e8e8e8;
        width: 100%;
        height: 50px;
        overflow-x: auto;
        overflow-y: scroll;
      }

      .account-settings-info-right {
        padding: 20px 40px;
      }
    }

    .account-settings-info-left {
      border-right: 1px solid #e8e8e8;
      width: 324px;
    }

    .account-settings-info-right {
      flex: 1 1;
      padding: 8px 40px;

      .account-settings-info-title {
        color: rgba(0, 0, 0, 0.85);
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 12px;
      }

      .account-settings-info-view {
        padding-top: 12px;
      }
    }
  }
</style>-->
