<template>
  <right-form-container :title=" currentItem.name + '的清运统计'" v-model="visible" @close="close">
    <a-spin :spinning="spinShow" :delay="200">
      <div v-malihu-scrollbar class="mCustomScrollbar" style="overflow-y: auto;" :style="{ 'max-height': bodyHeight + 'px' }">
        <div class="p-12">
          <a-form layout="inline">
            <a-row>
              <a-col :span="12">
                <a-form-item label="所属部门">
                  <span>{{ formItem.carInfo.department.name|| '' }}</span>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="车辆负责人">
                  <span>{{ formItem.chargeUser.name||''}}</span>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-item label="司机姓名">
                  <span>{{ formItem.driver.name||''}}</span>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="司机电话">
                  <span>{{ formItem.driver.mobilePhoneNumber||''}}</span>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="p-12 p-t-12">
          <a-row :gutter="16">
            <a-col :span="12">
              <metrics value="清运次数"><span slot="title">{{ statisticsData.records || '0' }} 次</span></metrics>
            </a-col>
            <a-col :span="12">
              <metrics value="清运公里"><span slot="title">{{ statisticsData.mileages ? statisticsData.mileages.toFixed(2) : '0'  }} km</span></metrics>
            </a-col>
          </a-row>
          <a-row :gutter="16" class="m-t-12">
            <a-col :span="12">
              <metrics value="清运时长"><span slot="title">{{ statisticsData.durations || '0' }}</span></metrics>
            </a-col>
            <a-col :span="12">
              <metrics value="清运吨数"><span slot="title">{{ statisticsData.tons || '0' }} 吨</span></metrics>
            </a-col>
          </a-row>
        </div>
      </div>
      <a-divider style="margin: 10px 0 6px 0;"></a-divider>
      <div class="p-12 p-b-20">
        <a-row :gutter="20">
          <a-col :span="24">
            <a-button block type="primary" icon="database" @click="openHistoryModal">历史清运记录</a-button>
          </a-col>
        </a-row>
      </div>
    </a-spin>
    <a-modal
      :title="currentItem.name + '的清运历史记录'"
      :maskClosable="false" :footer="null"
      width="1600px" v-model="historyModal">
      <UserHistoryList ref="historyListRef"></UserHistoryList>
    </a-modal>
  </right-form-container>
</template>

<script>
  import moment from 'moment'
  import { mapState } from 'vuex'
  import SERVICE_URLS from '@/api/service.url'
  import containerClose from '.././mixins/container-close'
  import RightFormContainer from './RightFormContainer'
  import UserHistoryList from '../../car/info/UserHistoryList'
  import Metrics from './Metrics'

  export default {
    name: 'DriverDetail',
    components: { UserHistoryList, RightFormContainer, Metrics },
    mixins: [containerClose],
    data () {
      return {
        spinShow: false,
        currentItem: {
          name: ''
        },
        formItem: {},
        // 巡检历史记录
        historyModal: false,
        // 统计数据
        statisticsData: {},
        searchParams: {
          startTime: '',
          endTime: '',
          userId: '',
          number: 0,
          size: 10,
          direction: '',
          order: '',
          driver: null
        }
      }
    },
    computed: {
      ...mapState({
        bodyHeight: (state) => {
          return state.app.bodyHeight - 200
        }
      })
    },
    methods: {
      moment,
      loadDriverInfo (item) {
        this.currentItem = item.driver
        this.searchParams.driver = item.driver.id
        this.loadDriverStatistics()
        this.formItem = item
      },
      loadDriverStatistics () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.carInfoApi.statistics,
          data: {
            driver: this.searchParams.driver
          },
          noTips: true,
          success: (data) => {
            this.statisticsData = data.body
          }
        })
      },

      /**
       * 打开历史记录modal
       */
      openHistoryModal () {
        this.historyModal = true
        this.$nextTick(() => {
          this.$refs.historyListRef.searchRecord(this.currentItem)
        })
      },

      close () {
        this.visible = false
        this.$emit('input', this.visible)
        this.$emit('close')
        this.map = null
      }
    }
  }
</script>
