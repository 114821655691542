<template>
  <right-form-container :title="currentItem.name" v-model="visible" @close="close">
    <a-spin :spinning="spinShow" :delay="200">
      <div v-malihu-scrollbar class="mCustomScrollbar" style="overflow-y: auto;" :style="{ 'max-height': bodyHeight + 'px' }">
        <div class="p-12">
          <a-form layout="inline">
            <a-row :gutter="20">
              <a-col :span="12">
                <a-form-item label="编号">
                  <span>{{ formItem.no }}</span>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="负责人电话">
                  <span>{{ formItem.userPhone }}</span>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="20">
              <a-col :span="12">
                <a-form-item label="集污点类型">
                  <a-tag :color="formItem.type===spotTypeEnum.SANGE.value? 'blue' :formItem.type===spotTypeEnum.JIWUCHI.value?'red' : 'green'">
                    {{ getSpotType(formItem) }}
                  </a-tag>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="容积">
                  <span>{{ formItem.bulk }}  m³</span>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="20">
              <a-col :span="24">
                <a-form-item label="区域">
                  <span>{{ formItem.areaName }}</span>
                </a-form-item>
              </a-col>
            </a-row>
            <!--            <a-row :gutter="20">
                          <a-col :span="12">
                            <a-form-item label="院内院外">
                              <span>{{ formItem.positionType === 'nei' ? '院内' : '院外' }}</span>
                            </a-form-item>
                          </a-col>
                          <a-col :span="12">
                            <a-form-item label="门牌号">
                              <span>{{ formItem.houseNo }}</span>
                            </a-form-item>
                          </a-col>
                        </a-row>
                        <a-row :gutter="20">
                          <a-col :span="12">
                            <a-form-item label="经度">
                              <span>{{ formItem.latitude }}</span>
                            </a-form-item>
                          </a-col>
                          <a-col :span="12">
                            <a-form-item label="纬度">
                              <span>{{ formItem.longitude }}</span>
                            </a-form-item>
                          </a-col>
                        </a-row>-->
            <a-row :gutter="20">
              <a-col :span="24">
                <a-form-item label="详细位置">
                  <span>{{ formItem.location }}</span>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="banner-img">
          <button v-if="formItem.monitorPointNo !== null"
                  class="left_btn preview-img image-top-btn"
                  @click="onClickImage"
                  style="cursor: pointer;"
          >
            更多图片
          </button>
          <button v-if="formItem.monitorPointNo !== null" class="right_btn image-top-btn" @click="moreVideo" style="cursor: pointer;">视频监控</button>
          <div style="width: 94%;height: 100px;margin: 0 auto" v-if="formItem.monitorPointNo !== null">
            <img v-if="images.length > 0" :src="images[0].src + '?imageView2/1/w/450/h/100'" style="width: 100%"/>
            <img v-else src="../../../../assets/zanwu.jpg" style="object-fit: cover; width: 100%; height: 100px;"/>
          </div>
        </div>
        <template v-if="viewByNoData">
          <a-dropdown style="text-align: center; color: #ffffff; background: red;" v-if="viewByNoData.warningInfos.content.length>0">
            <div
              class="warn-info ant-dropdown-link" style="cursor: pointer;"
            >
              预警信息
            </div>
            <a-menu slot="overlay">
              <template v-for="item in viewByNoData.warningInfos.content">
                <a-menu-item>
                  <div
                    @click="clickWarningInfo(item.id)"
                    :style="{ color: item.warningType === 'FAULT' ? '#f5222d' : '#1890ff' }"
                  >
                    <a-icon
                      :type="item.warningType === 'FAULT' ? 'warning' : 'info-circle'"
                      style="margin-right: 10px;"
                    />
                    {{ item.title }}
                  </div>
                </a-menu-item>
                <a-menu-divider/>
              </template>
            </a-menu>
          </a-dropdown>
        </template>
        <div class="p-12 p-t-12">
          <a-row :gutter="16">
            <a-col :span="12">
              <metrics value="清运次数"><span slot="title">{{ formItemForecast.numbers }}</span></metrics>
            </a-col>
            <a-col :span="12">
              <metrics value="上次清运时间"><span slot="title">{{ formItemForecast.lastTime }} </span></metrics>
            </a-col>
          </a-row>
          <a-row :gutter="16" class="m-t-12">
            <a-col :span="12">
              <metrics value="清运周期"><span slot="title">{{ formItemForecast.cycle }}</span></metrics>
            </a-col>
            <a-col :span="12">
              <metrics value="预计清运时间"><span slot="title">{{ formItemForecast.nextTime }} </span></metrics>
            </a-col>
          </a-row>
          <a-row :gutter="16" class="m-t-12" v-if="formItem.monitorPointNo !== null">
            <a-col :span="12">
              <metrics value="实际液位">
                  <span slot="title" v-if="viewByNoData">
                     <template v-for="item in viewByNoData.metrics">
                        <span style="cursor: pointer" v-if="item.enNameAlias==='WATER_HEIGHT'" @click="onClickMetrics(item)">{{ item.value }}{{ item.unit }}</span>
                     </template>
                  </span>
              </metrics>
            </a-col>
            <a-col :span="12">
              <metrics value="空高">
                  <span slot="title" v-if="viewByNoData">
                     <template v-for="item in viewByNoData.metrics">
                        <span style="cursor: pointer" v-if="item.enNameAlias==='EMPTY_HEIGHT'" @click="onClickMetrics(item)">{{ item.value }}{{ item.unit }}</span>
                        </template>
                  </span>
              </metrics>
            </a-col>
          </a-row>
        </div>
      </div>
      <a-divider style="margin: 10px 0 6px 0;"></a-divider>
      <div class="p-12 p-b-20">
        <a-row :gutter="20">
          <a-col :span="12">
            <a-button block type="primary" icon="sync" @click="spotFixed">集污点定位</a-button>
          </a-col>
          <a-col :span="12">
            <a-button block type="primary" icon="database" @click="openHistoryModal">报抽记录</a-button>
          </a-col>
        </a-row>
      </div>
    </a-spin>
    <a-modal
      :title="currentItem.type === spotTypeEnum.SANGE.value ? (currentItem.name +'家的三格化粪池') :currentItem.type === spotTypeEnum.DANWENG.value ? (currentItem.name +'家的单翁'):currentItem.name"
      :maskClosable="false" :footer="null" :width="1300"
      v-model="historyModal">
      <spot-history-list ref="historyListRef" :spotNo="currentItem.no"></spot-history-list>
    </a-modal>
    <a-modal
      :title="'视频监控-' + formItem.name"
      @cancel="detailClose"
      :maskClosable="false"
      width="1048px"
      :footer="null"
      :visible="detailDrawer"
      :bodyStyle="{padding:'0'}"
    >
      <a-tabs type="line" :activeKey="selectedType" @change="hKTabscallback">
        <a-tab-pane key="hkvideoPlay" tab="实时视频" :bodyStyle="{padding:'1'}">
          <h-k-player ref="hkPlayerRef" :cameraIndexCodes="testCameraIndexCodes"></h-k-player>
        </a-tab-pane>
        <a-tab-pane key="hkvideoPlayBack" tab="视频回放" :bodyStyle="{padding:'1'}">
          <h-k-back-player ref="hkPlayerBackRef" :cameraIndexCodes="testCameraIndexCodes"></h-k-back-player>
        </a-tab-pane>
      </a-tabs>
    </a-modal>
    <a-modal
      :title="metricsTitle+'-历史数据'"
      :visible="metricsVisible"
      @cancel="handleCancel"
      :footer="null"
      :width="1200"
      :destroyOnClose="true"
    >
      <MetricsHistory ref="metricsRef" :unit=metricsUnit></MetricsHistory>
    </a-modal>
  </right-form-container>
</template>

<script>
import moment from 'moment'
import SERVICE_URLS from '@/api/service.url'
import { mapState } from 'vuex'
import containerClose from '../mixins/container-close'
import RightFormContainer from './RightFormContainer'
import { gatherSpotFormVO } from '../../gatherspot/common/common'
import SpotHistoryList from './SpotHistoryList'
import Metrics from './Metrics'
import { previewConfig } from '../../../../utils/preview'
import HKPlayer from '../../hkcamera/HKPlayer'
import HKBackPlayer from '../../hkcamera/HKBackPlayer'
import MetricsHistory from './MetricsHistory'
import { spotTypeEnum } from '@/constant/spot/spotType'

export default {
  name: 'SpotDetail',
  components: { SpotHistoryList, RightFormContainer, Metrics, HKPlayer, HKBackPlayer, MetricsHistory },
  mixins: [containerClose],
  data () {
    return {
      spinShow: false,
      currentItem: {},
      formItem: {},
      historyModal: false,
      formItemForecast: {},
      viewByNoData: null,
      images: [],
      config: previewConfig,
      detailDrawer: false,
      selectedType: 'hkvideoPlay',
      testCameraIndexCodes: [],
      metricsVisible: false,
      metricsUnit: '',
      metricsTitle: '',
      spotTypeEnum: spotTypeEnum
    }
  },
  computed: {
    ...mapState({
      bodyHeight: (state) => {
        return state.app.bodyHeight - 200
      }
    })
  },
  methods: {
    moment,
    /**
     * 加载集污点详细信息
     * @param item 左侧点击item
     */
    loadData (item) {
      this.currentItem = item
      this.formItem = gatherSpotFormVO()
      this.$http(this, {
        url: SERVICE_URLS.csgz.gatherSpot.view,
        params: {
          id: item.id
        },
        noTips: 'true',
        success: (data) => {
          Object.assign(this.formItem, data.body)
          this.loadForecastData(item.id)
          if (data.body.monitorPointNo !== null && data.body.monitorPointNo !== '' && data.body.monitorPointNo !== undefined && data.body.monitorPointNo !== 'null') {
            this.$http(this, {
              url: SERVICE_URLS.equipment2.monitoringPoint.view_by_no,
              data: {
                no: data.body.monitorPointNo
              },
              noTips: 'true',
              success: (data) => {
                this.viewByNoData = data.body
                this.imageForEach(this.viewByNoData.sceneImages)
              }
            })
          } else {
            this.viewByNoData = null
            this.images = []
          }
        }
      })
    },
    // 更多图片展示
    imageForEach (item) {
      this.images = []
      item.forEach((data) => {
        this.images.push({
          src: data.bucketUrl + data.fileUrl,
          msrc: data.bucketUrl + data.fileUrl + '?imageView2/2/w/300/h/300',
          w: data.w,
          h: data.h,
          title: data.name,
          id: data.id
        })
      })
    },
    loadForecastData (id) {
      this.$http(this, {
        url: SERVICE_URLS.csgz.loopForecastApi.findByGatherSpot,
        data: {
          gatherSpotId: id
        },
        noTips: 'true',
        success: (data) => {
          if (data.body) {
            this.formItemForecast = data.body
          } else {
            this.formItemForecast = {}
          }
        }
      })
    },
    //集污点定位
    spotFixed () {
      this.$emit('onSpotFixed', this.formItem)
    },

    /**
     * 打开历史记录modal
     */
    openHistoryModal () {
      this.historyModal = true
      this.$nextTick(() => {
        this.$refs.historyListRef.search()
      })
    },

    close () {
      this.visible = false
      this.$emit('input', this.visible)
      this.$emit('close')
      this.map = null
    },
    moreVideo () {
      if (this.viewByNoData.cameras.length > 0) {
        this.testCameraIndexCodes = []
        this.detailDrawer = true
        this.testCameraIndexCodes = this.viewByNoData.cameras
        this.$nextTick(() => {
          this.$refs.hkPlayerRef.play()
        })
      } else {
        this.$message.warning('暂无视频')
      }
    },
    detailClose () {
      this.detailDrawer = false
      this.$refs.hkPlayerRef.closeHandle()
      if (this.$refs.hkPlayerBackRef) this.$refs.hkPlayerBackRef.closeHandle()
      this.selectedType = 'hkvideoPlay'
    },
    hKTabscallback (key) {
      this.selectedType = key
      if (key === 'hkvideoPlay') {
        this.$nextTick(() => {
          this.$refs.hkPlayerBackRef.closeHandle()
        })
        this.$refs.hkPlayerRef.play()
      } else {
        this.$refs.hkPlayerRef.closeHandle()
        this.$nextTick(() => {
          this.$refs.hkPlayerBackRef.play()
        })
      }
    },
    onClickImage () {
      if (this.images.length > 0) {
        this.$preview.open(0, this.images, this.config)
      } else {
        this.$message.warning('暂无图片')
      }
    },
    onClickMetrics (item) {
      if (item) {
        this.metricsUnit = item.unit
        this.metricsTitle = item.name
        this.metricsVisible = true
        this.$nextTick(() => {
          this.$refs.metricsRef.realId = item.id
          this.$refs.metricsRef.handleMetricsHistory()
        })
      }
    },
    handleCancel () {
      this.metricsVisible = false
    },
    getSpotType (item) {
      if (item.type === spotTypeEnum.JIWUCHI.value) {
        return '集污池'
      } else if (item.type === spotTypeEnum.SANGE.value) {
        return '三格化粪池'
      } else if (item.type === spotTypeEnum.DANWENG.value) {
        return '单翁'
      } else if (item.type === spotTypeEnum.LANI.value) {
        return '拉泥'
      } else if (item.type === spotTypeEnum.GONGCE.value) {
        return '公厕'
      } else {
        return '其他'
      }
    }
  }
}
</script>
<style scoped>
.banner-img {
  width: 100%;
  position: relative;
}

.image-top-btn {
  font-size: 12px;
  color: #ffffff;
  background-color: rgb(76, 69, 69);
  border: none;
  border-radius: 2px;
  outline: none;
  text-align: center;
}

.left_btn {
  position: absolute;
  left: 32px;
  top: 50px;
}

.right_btn {
  position: absolute;
  right: 35px;
  top: 50px;
}
</style>
