const searchListCommon = {
  props: {
    searchKey: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      spinShow: false,
      pagination: {
        current: 1,
        total: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
      }
    }
  },
  watch: {
    searchKey (val) {
      this.searchKeyChange(val)
    }
  },
  methods: {

    /**
     * 执行检索
     * 会重置分页信息
     */
    onSearch () {
      this.resetPage()
      this.loadData()
    },

    /*分页改变*/
    onPageChange (page, pageSize) {
      this.searchParams.page = page - 1
      this.searchParams.size = pageSize
      this.loadData()
    },
    /**
     * 重置分页信息
     */
    resetPage () {
      this.searchParams.page = 0
    }
  }
}

export default searchListCommon