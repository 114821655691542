import SERVICE_URLS from '../../../../api/service.url'

const car = {
  data () {
    return {
      carOverlays: [],
      carOverlayGroup: undefined
    }
  },
  methods: {
    /**
     * 加载地图Car图层数据
     * @param mapId 服务端地图Map Id
     * @param map 前端高德地图初始化map对象
     */
    /*初始化加载素有车辆信息*/
    getAllCarInfo () {
      this.$http(this, {
        url: SERVICE_URLS.csgz.carInfoApi.mapList,
        data: {
          useType: 'QY'
        },
        loading: 'no',
        noTips: true,
        success: (data) => {
          this.buildAllCarInfo(data)
        },
        error: () => {
        }
      })
    },
    buildAllCarInfo (data) {
      this.buildMarker(data.body)
      // if (!this.map) return
      // this.map.add(this.carOverlays)
    },
    /*图标标记*/
    buildMarker (list) {
      this.clearCarOverlays()
      this.carOverlays = []
      list.forEach((x) => {
        /*icon_car_green.png icon_qy_car_green.png  icon_qy_cat_red.png  icon_xj_car.png*/
        /*360 - course - 90,*/
        var icon = new AMap.Icon({
          size: new AMap.Size(60, 28), // 图标尺寸
          image: 'http://files.fangshuoit.com/icon_car_green.png', // Icon的图像
          imageSize: new AMap.Size(60, 28) // 根据所设置的大小拉伸或压缩图片
        })
        if (x.useType === 'QY') {
          icon = new AMap.Icon({
            size: new AMap.Size(40, 24), // 图标尺寸
            image: 'http://files.fangshuoit.com/icon_car_green.png', // Icon的图像
            imageSize: new AMap.Size(40, 24) // 根据所设置的大小拉伸或压缩图片
          })
        } else {
          icon = new AMap.Icon({
            size: new AMap.Size(40, 24), // 图标尺寸
            image: 'http://files.fangshuoit.com/icon_xj_car.png', // Icon的图像
            imageSize: new AMap.Size(40, 24) // 根据所设置的大小拉伸或压缩图片
          })
        }
        // 将 Icon 实例添加到 marker 上:
        var marker = new AMap.Marker({
          position: new AMap.LngLat(x.longitude, x.latitude),
          offset: new AMap.Pixel(-30, -14),
          title: x.fullName,
          icon: icon,
          angle: 360 - x.course - 90,
          zoom: 15,
          zIndex: 180,
          extData: {
            key: x
          }
        })
        marker.setLabel({
          offset: new AMap.Pixel(0, 0), //设置文本标注偏移量
          content: '<div class="car-marker">' + x.platNumber + '</div>', //设置文本标注内容
          direction: 'top' //设置文本标注方位
        })
        if ((x.longitude !== '0') && (x.latitude !== '0')) {
          marker.on('click', this.showCarInfo)
          this.carOverlays.push(marker)
        }
      })
      this.getCarOverlayGroup().addOverlay(this.carOverlays)
      this.getCarOverlayGroup().setMap(this.map)
      this.showCarOverlaysGroup()
    },
    showCarInfo (e) {
      this.dropdpwnVisible = false
      this.areaTreeVisible = false
      const item = e.target.getExtData()['key']
      this.carItemClickHandle('car', item)
    },
    carItemClickHandle (type, item) {
      this.carFixed(item)
      this.rightFormChecked = type
      this.rightFormShow = true
      if (this.setSelectedMarkerStyle) {
         this.setSelectedMarkerStyle([item.longitude, item.latitude])
      }
      this.$nextTick(() => {
        this.getRightDetailRef().loadData(item.id)
      })
    },
    carFixed (item) {
      this.map.setCenter([item.longitude, item.latitude])
      this.map.setZoom(15)
    },
    showCarOverlaysGroup () {
      this.getCarOverlayGroup().show()
    },
    hideCarOverLaysGroup () {
      this.getCarOverlayGroup().hide()
    },
    clearCarOverlays () {
      this.getCarOverlayGroup().clearOverlays()
    },
    getCarOverlayGroup () {
      if (this.carOverlayGroup === undefined) {
        this.carOverlayGroup = new AMap.OverlayGroup()
      }
      return this.carOverlayGroup
    },
    handleCarType (e) {
      this.carTypeValue = e.target.value
      if (this.carTypeValue === '0') {
        this.showCarOverlaysGroup()
      } else {
        this.hideCarOverLaysGroup()
      }
    }
  }
}
export default car
