<template>
  <a-spin :spinning="spinShow">
    <div class="form-search-body" style="padding:20px;">
      <div class="center" style="margin-bottom: 10px;">
        <a-radio-group defaultValue="ALL" buttonStyle="solid" @change="onSearch" v-model="carStatus">
          <a-radio-button value="ALL">全部</a-radio-button>
          <a-radio-button value="GOING">执行中</a-radio-button>
          <a-radio-button value="FREE">空闲</a-radio-button>
          <a-radio-button value="OFFLINE">离线</a-radio-button>
        </a-radio-group>
      </div>
      <a-list :bordered="false" :dataSource="searchData" style="font-size: 13px">
        <a-list-item
          style="cursor: pointer"
          slot="renderItem"
          @click.native="locateCover(item)"
          class=" default-hover"
          slot-scope="item">
          <a-col :span="2">
            <a-icon type="car"/>
          </a-col>
          <a-col :span="17"> {{ item.platNumber }}
            <template v-if="item.bind">
              <template v-if="item.offline === '1'">
                <a-badge color="#2db7f5"/>{{item.stillTimeStr ? '静止(' + item.stillTimeStr + ')' : '静止'}}
              </template>
              <template v-if="item.offline === '2'">
                <a-badge color="green" status="processing"/>行驶
              </template>
              <template v-if="item.offline === '0'">
                <a-badge color="red"/>{{item.offlineTimeStr ? '离线(' + item.offlineTimeStr + ')' : '离线'}}
              </template>
            </template>
            <template v-else>(未绑定)</template>
          </a-col>
          <a-col :span="5" style="text-align: right;">
            <a-tag v-if="item.status === 'FREE'">空闲</a-tag>
            <a-tag color="green" v-if="item.status === 'GOING'">执行中</a-tag>
          </a-col>
        </a-list-item>
        <div slot="footer">
          <a-pagination
            size="small"
            :current="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            :showTotal="pagination.showTotal"
            @change="onPageChange"/>
        </div>
      </a-list>
    </div>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'SearchCarList',
    props: {
      searchKey: {
        type: String,
        default: 'car'
      }
    },
    data () {
      return {
        spinShow: false,
        carStatus: 'ALL',
        searchParams: {
          number: 0,
          size: 10,
          direction: '',
          order: '',
          useType: 'QY'
        },
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
        },
        searchData: []
      }
    },
    watch: {
      searchKey () {
        this.searchParams.platNumber = this.searchKey
      }
    },
    created () {
      this.onSearch()
    },
    methods: {
      /*搜索方法*/
      onSearch () {
        this.searchParams.status = this.carStatus
        this.searchParams.offline = '1'
        if (this.carStatus === 'ALL') {
          this.searchParams.status = ''
          this.searchParams.offline = ''
        } else if (this.carStatus === 'OFFLINE') {
          this.searchParams.status = ''
          this.searchParams.offline = '0'
        }
        this.$http(this, {
          url: SERVICE_URLS.csgz.carInfoApi.search,
          data: this.searchParams,
          noTips: true,
          loading: 'spinShow',
          success: (data) => {
            this.searchData = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = this.searchParams.number + 1
          },
          error: () => {
          }
        })
      },
      /*分页改变*/
      onPageChange (page, pageSize) {
        this.searchParams.number = page - 1
        this.searchParams.size = pageSize
        this.onSearch()
      },
      /*选中定位覆盖物*/
      locateCover (item) {
        this.$emit('onItemClick', 'car', item)
      }
    }
  }
</script>
