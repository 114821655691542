import SERVICE_URLS from '../../../../api/service.url'

const sewagePlant = {
  data () {
    return {
      sewagePlantOverlays: [],
      sewagePlantOverlayGroup: undefined
    }
  },
  methods: {
    /*初始化加载所有污水处理厂信息*/
    getSewagePlant () {
      this.$http(this, {
        url: SERVICE_URLS.csgz.sewagePlantApi.select,
        data: {
        },
        loading: 'no',
        noTips: true,
        success: (data) => {
          this.buildSewagePlant(data)
        },
        error: () => {
        }
      })
    },
    buildSewagePlant (data) {
      this.buildSewagePlantMarker(data.body)
      if (!this.map) return
      this.map.add(this.sewagePlantOverlays)
    },
    /*集污点图标标记*/
    buildSewagePlantMarker (list) {
      this.sewagePlantOverlays = []
      list.forEach((x) => {
        // 创建 AMap.Icon 实例：
        var icon = new AMap.Icon({
          size: new AMap.Size(30, 30), // 图标尺寸
          image: 'https://sosfile.fangshuoit.com/o_1ec258ru2rtq1q69fl51oki112i1t.png', // Icon的图像
          imageSize: new AMap.Size(30, 30) // 根据所设置的大小拉伸或压缩图片
        })
        // 将 Icon 实例添加到 marker 上:
        var marker = new AMap.Marker({
          position: new AMap.LngLat(x.longitude, x.latitude),
          offset: new AMap.Pixel(-30, -14),
          title: x.name,
          icon: icon,
          zoom: 15,
          extData: {
            key: x
          }
        })
        marker.setLabel({
          offset: new AMap.Pixel(0, 0), //设置文本标注偏移量
          content: '<div class="car-marker">' + x.name + '</div>', //设置文本标注内容
          direction: 'top' //设置文本标注方位
        })
        if ((x.longitude !== '0') && (x.latitude !== '0')) {
          // marker.on('click', this.showInfo)
          this.getSewagePlantOverlayGroup().addOverlay(marker)
          this.sewagePlantOverlays.push(marker)
        }
      })
      this.map.setFitView()
    },
    showInfo (e) {
      const item = e.target.getExtData()['key']
      console.info(item)
    },
    showSpotOverlaysGroup () {
      this.getSpotOverlayGroup().setMap(this.map)
      this.getSpotOverlayGroup().show()
    },
    clearSpotOverlays () {
      this.getSpotOverlayGroup().clearOverlays()
    },
    getSewagePlantOverlayGroup () {
      if (this.sewagePlantOverlayGroup === undefined) {
        this.sewagePlantOverlayGroup = new AMap.OverlayGroup()
      }
      return this.sewagePlantOverlayGroup
    }
    // eslint-disable-next-line no-dupe-keys
  }
}
export default sewagePlant
