/* eslint-disable no-new */
import Vue from 'vue'

import UUID from '../../../../utils/UUID'
import { mapState } from 'vuex'
import SERVICE_URLS from '@/api/service.url'
import mapStyle from './map-style'

const common = {
  mixins: [mapStyle],
  data () {
    return {
      mapContainerId: new UUID().id,
      map: undefined,
      q: '',
      searchStatus: false,
      // 左侧
      leftDropData: [
        {
          label: '车辆',
          value: 'car'
        },
        {
          label: '司机',
          value: 'driver'
        },
        {
          label: '集污点',
          value: 'spot'
        }
      ],
      mapDbConfig: undefined,
      dbLeftDropData: [],
      // 左侧下拉选中
      leftDropChecked: 'car',
      // 右侧表单选中
      rightFormChecked: '',
      // 当前的图层下拉集合
      currentLayerSelected: [],
      rightFormShow: false,
      leftFormShow: true,
      // 定义页面所有显示的悬浮框
      modalNames: ['rightFormShow', 'leftFormShow'],
      isPageInit: true,
      districtLayer: [],
      defaultSelectedValue: [],
      markerCluster: null,
      districtOverlayGroups: {},
      firstDisrtictLevel: null,
      dictictCount: 1,
      isLoadRoadNetLayer: false
    }
  },
  computed: {
    ...mapState({
      intervalMessage (state) {
        //必须放在nextTick中执行，否则interval操作了属性可能有状态变化
        this.$nextTick(() => {
          if (!this.isPageInit) {
            this.intervalHandle()
          } else {
            this.isPageInit = false
          }
        })
        return state.common.intervalMessage
      }
    })
  },
  methods: {
    /**
     * 加载地图配置信息
     */
    init () {
      // 初始化地图
      this.initMap()

      this.$nextTick(() => {
        this.initLeftDropData()
        this.getLayerRef().loadLayerTree()
      })
    },

    /**
     * 设置地图区域显示
     */
    setDistrict () {
      if (this.mapDbConfig.adcode) {
        if (!this._isInitDist) {
          this._isInitDist = true
          this._initDistrictOverLayGroup(this.mapDbConfig.adcode)
          //为了防止区县数据还没有加载完成 就已经执行了zoomchange了 故 在判断所有数据加载完成后 再去执行zoomchange，为了防止绘画不上
          this.distLoadCallBack = setInterval(() => {
            if (this.dictictCount === 0) {
              this.districtGroupZoomChange()
              window.clearInterval(this.distLoadCallBack)
            }
          }, 1000)
        }
        this.districtGroupZoomChange()
      }
    },
    /**
     * 重置地图数据
     */
    restMap () {
      this.currentLayerSelected = []
      this.rightFormShow = false
      this.clearCarOverlays()
      this.clearXunjianOverlays()
      this.clearDeviceOverlays()
      if (this.markerCluster) this.markerCluster.clearMarkers()
      // 地图清空所有覆盖物
      this.map.clearMap()
      this.clearDistrictOverLayGroup()
    },

    /**
     * 初始化地图
     */
    initMap () {
      if (!this.map) {
        var me = this
        this.map = new AMap.Map(this.mapContainerId, {
          mapStyle: this.getMapStyle(),
          resizeEnable: true,
          //   center: [106.230909, 38.487193],
          center: [105.973157, 37.338498],
          /*'bg', 'road', 'building', 'point'*/
          //  features: ['bg', 'road', 'building', 'point'],
          zoom: 8
        })
        if (this.setMapFeatures) {
          this.setMapFeatures()
        } else {
          this.map.setFeatures(['bg', 'road', 'building'])
        }
        this.map.on('zoomchange', function () {
          me.mapZoomchange()
        })
        this.map.on('moveend', function () {
          if (me.removeOverMarkTips) {
            me.removeOverMarkTips()
          }
        })
        this.initMarkerCluster()
        this.buildMapOtherLayer()
        this.roadNetLayer = new AMap.TileLayer.RoadNet()
        this.addRoadNetLayer()
      }
    },
    addRoadNetLayer () {
      this.map.add(this.roadNetLayer)
      this.isLoadRoadNetLayer = true
    },
    onSearch () {
      this.leftFormShow = true
      this.$nextTick(() => {
        if (this.getLeftFormRef()) this.getLeftFormRef().onSearch(this.q)
      })
    },
    /**
     * 获取左侧选中的Form 引用
     */
    getLeftFormRef () {
      return this.$refs[this.leftDropChecked + 'Ref']
    },

    /**
     * 获取右侧显示的详情引用
     */
    getRightDetailRef () {
      return this.$refs[this.rightFormChecked + 'DetailRef']
    },

    /**
     * 获取图层引用
     * @returns
     */
    getLayerRef () {
      return this.$refs['coverageRef']
    },

    /**
     * 左侧下拉菜单变化
     */
    leftDropChange () {
      this.searchStatus = false
      this.leftFormShow = true
      this.q = ''
      this.$nextTick(() => {
        if (this.getLeftFormRef()) this.getLeftFormRef().onSearch()
      })
      // this.getLayerRef().leftDropChangeLayer(this.leftDropChecked)

    },
    /**
     * 关闭右侧弹出框
     */
    closeRightForm () {
    },
    /**
     * 左侧Form行点击事件
     * @param type 框类型
     * @param item 选中值
     */
    formItemClick (type, item) {
      if (type === 'car') {
        this.carItemClick(type, item)
      } else if (type === 'spot') {
        this.spotItemClickHandle(type, item)
      } else if (type === 'driver') {
        this.driverItemClickHandle(type, item)
      }
    },

    spotItemClickHandle (type, item) {
      // this.spotFixed(item)
      this.rightFormChecked = type
      this.rightFormShow = true
      this.$nextTick(() => {
        this.getRightDetailRef().loadData(item)
      })
    },
    carItemClick (type, item) {
      this.mapFixed(item)
      this.rightFormChecked = type
      this.rightFormShow = true
      this.$nextTick(() => {
        this.getRightDetailRef().loadData(item.id)
      })
    },
    mapFixed (item) {
      this.setSelectedMarkerStyle([item.longitude, item.latitude])
      this.map.setCenter([item.longitude, item.latitude])
      this.map.setZoom(15)
    },
    driverItemClickHandle (type, item) {
      this.rightFormChecked = type
      this.rightFormShow = true
      this.$nextTick(() => {
        this.$refs['carDetailRef'].loadDriverInfo(item)
        // this.getRightDetailRef().loadDriverInfo(item)
      })
    },
    /**
     * 右侧图层变化
     * @param selectedValue 右侧图层下拉选中值
     */
    layerOnChange (selectedValue) {
      if (this.canLoadOverlays('monitoringPoint')) {
        // 隐藏或者显示设备覆盖物
        this.showOrHideDeviceOverlayGroup(selectedValue)
      }

      if (this.leftDropData.length > 1) {
        if (this.canLoadOverlays('car')) {
          // 包含CAR
          if (
            this.currentLayerSelected.findIndex((x) => x.indexOf('CAR') !== -1) === -1 &&
            selectedValue.findIndex((x) => x.indexOf('CAR') !== -1) !== -1
          ) {
            this.loadCarOverlays(this.mapId, this.map)
          } else if (selectedValue.findIndex((x) => x.indexOf('CAR') !== -1) === -1) {
            this.clearCarOverlays()
          }
        }

        if (this.canLoadOverlays('xunjian')) {
          // 包含INSPECT
          if (
            this.currentLayerSelected.findIndex((x) => x.indexOf('INSPECT') !== -1) === -1 &&
            selectedValue.findIndex((x) => x.indexOf('INSPECT') !== -1) !== -1
          ) {
            this.loadXunjianOverlays()
          } else if (selectedValue.findIndex((x) => x.indexOf('INSPECT') !== -1) === -1) {
            this.clearXunjianOverlays()
          }
        }
      }

      this.setCurrentLayerSelected(selectedValue)
    },

    // 关页面所有弹框
    closeAllModal () {
      this.modalNames.forEach((x) => {
        this[x] = false
      })
    },

    /**
     * Start
     * 左侧搜索事件执行开始执行回调
     */
    searchStartHandle () {
      this.searchStatus = true
    },

    /**
     * Success
     * 左侧搜索事件执行成功执行回调
     */
    searchSuccessHandle () {
      this.searchStatus = false
    },

    /**
     * Error
     * 左侧搜索事件执行错误执行回调
     */
    searchErrorHandle () {
      this.searchStatus = false
    },

    /**
     * 设置当前下拉数据
     * @param selectedValue
     */
    setCurrentLayerSelected (selectedValue) {
      this.currentLayerSelected = selectedValue.concat()
    },

    /**
     * 城市名称改变，切换城市
     * @param cityName
     */
    cityNameChange (cityName) {
      let geocoder
      let self = this
      AMap.plugin('AMap.Geocoder', () => {
        geocoder = new AMap.Geocoder({
          city: cityName
        })
      })
      geocoder.getLocation(cityName, (status, result) => {
        if (status === 'complete' && result.geocodes.length) {
          let lnglat = result.geocodes[0].location
          self.map.setCenter(lnglat)
        }
      })
    },

    /**
     * 轮训触发方法
     */
    intervalHandle () {
      // 当前选中中存在 巡检图层
      if (this.currentLayerSelected.findIndex((x) => x.indexOf('INSPECT') !== -1) !== -1) {
        this.updateXunjianOverlays()
      }
    },

    /**
     * 设置地图以某个覆盖物为中心点
     */
    mapFitView (overlay) {
      if (!this.map) return
      const zoom = this.map.getZoom()
      this.map.setFitView(overlay)
      this.map.setZoom(zoom)
    },

    /**
     * 设置地图自适应覆盖物
     */
    setMapFitView (layers) {
      if (!this.map) return
      if (layers) {
        this.map.setFitView(layers, true, [0, 0, 0, 0])
      } else {
        this.map.setFitView()
      }
    },

    /**
     * 检查能否渲染覆盖物
     * @param dropDataType
     */
    canLoadOverlays (dropDataType) {
      return this.dbLeftDropData.find((data) => data.value === dropDataType)
    },
    loactionWarnInfo (warnInfoData) {
      if (warnInfoData.position) {
        var potionPoints = eval(warnInfoData.position)
        if (warnInfoData.overlayType === 'marker') {
          console.log(warnInfoData)
          this.map.setZoom(16)
          this.map.setCenter(potionPoints)
        } else {
          var centPoint = [0.0, 0.0]
          potionPoints.forEach((point) => {
            centPoint[0] = (centPoint[0] + point[0]) / 2
            centPoint[1] = (centPoint[1] + point[1]) / 2
          })
          // this.map.setCenter(centPoint)
        }
      }
    },
    /**
     * 初化左侧下拉数据
     */
    initLeftDropData () {
      this.$http(this, {
        url: SERVICE_URLS.equipment2.monitorPointApi.layerSelectType,
        noTips: true,
        params: { mapId: this.mapId },
        success: (data) => {
          this.mapDbConfig = data.body
          this.dbLeftDropData = data.body.layers
          // 只配置了一种业务
          if (this.dbLeftDropData.length < 3) {
            if (this.dbLeftDropData.findIndex((x) => x.value === 'spot') !== -1) {
              this.leftDropData = [
                {
                  label: '集污点',
                  value: 'spot'
                }
              ]
            } else if (this.dbLeftDropData.findIndex((x) => x.value === 'car') !== -1) {
              this.leftDropData = [
                {
                  label: '车辆',
                  value: 'car'
                }
              ]
            } else if (this.dbLeftDropData.findIndex((x) => x.value === 'driver') !== -1) {
              this.leftDropData = [
                {
                  label: '司机',
                  value: 'driver'
                }
              ]
            }
          } else {
            this.leftDropData = this.dbLeftDropData
          }

          if (this.leftDropData) {
            this.leftDropChecked = this.leftDropData[0].value
          } else {
            return
          }

          this.onSearch()

          // 组装一次性请求链接
          const urls = []
          let count = 0
          let monitoringPointCount = -1
          let overlaysCount = -1
          let xunjianCount = -1
          let carCount = -1
          if (this.canLoadOverlays('monitoringPoint')) {
            urls.push(
              Vue.http.post(SERVICE_URLS.equipment2.monitorPointApi.searchByLayer.path, {
                mapInfoId: this.mapId,
                typeLabels: []
              })
            )
            monitoringPointCount = count
            count++
          }
          if (this.canLoadOverlays('spot') && this.leftDropChecked === 'spot') {
            urls.push(
              Vue.http.get(SERVICE_URLS.xj.xjUserRealInfo.searchLocation.path.replace('{mapInfoId}', this.mapId))
            )
            xunjianCount = count
            count++
          }
          if (this.canLoadOverlays('car') && this.leftDropChecked === 'car') {
            urls.push(Vue.http.get(SERVICE_URLS.car.carInfoApi.mapList.path + '?mapId=' + this.mapId))
            carCount = count
          }
          Promise.all(urls)
            .then((res) => {
              if (monitoringPointCount !== -1) {
                this.buildMonitoringPointCoverList(res[monitoringPointCount], true)
              }
              if (overlaysCount !== -1) {
                this.buildCoverList(res[overlaysCount])
              }

              if (xunjianCount !== -1) {
                this.buildXunjianOverlays(res[xunjianCount])
              }

              if (carCount !== -1) {
                this.buildAllCarInfo(res[carCount])
              }
              if (this.mapDbConfig.fitViewType === 0) {
                this.setMapFitView()
              } else {
                // 设置行政区域划分
                this.setDistrict()
              }
            })
            .catch((error) => {
              console.log(error)
              this.$message.error('地图初始化数据加载错误！')
            })
        },
        error: () => {
          console.error('Load layerSelectType error.')
        }
      })
    },
    initMarkerCluster () {
      if (!this.markerCluster && AMap.MarkerClusterer) {
        var sts = [{
          url: '../../../cluster/m1.png',
          size: new AMap.Size(53, 53),
          offset: new AMap.Pixel(-26, -26)
        },
          {
            url: '../../../cluster/m2.png',
            size: new AMap.Size(56, 56),
            offset: new AMap.Pixel(-28, -28)
          },
          {
            url: '../../../cluster/m3.png',
            size: new AMap.Size(66, 66),
            offset: new AMap.Pixel(-33, -33)
          },
          {
            url: '../../../cluster/m4.png',
            size: new AMap.Size(78, 78),
            offset: new AMap.Pixel(-39, -39)
          },
          {
            url: '../../../cluster/m5.png',
            size: new AMap.Size(90, 90),
            offset: new AMap.Pixel(-45, -45)
          }]
        this.markerCluster = new AMap.MarkerClusterer(this.map, [], { gridSize: 40, maxZoom: 17, styles: sts })
      }
    },
    clearDistrictOverLayGroup () {
      this.districtSearchObj = null
      this._isInitDist = false
      this.firstDisrtictLevel = null
      for (var level in this.districtOverlayGroups) {
        this.districtOverlayGroups[level].clearOverlays()
      }
      this.districtOverlayGroups = {}
    },
    /**
     * 访问高德行政区划服务  加载省市县图层
     * @param adcode
     * @private
     */
    _initDistrictOverLayGroup (adcode) {
      if (!this.districtSearchObj) {
        var opts = {
          subdistrict: 1,
          extensions: 'all'
        }
        this.districtSearchObj = new AMap.DistrictSearch(opts)
        this.colorIndex = 0
      }
      this.districtSearchObj.search(adcode, (status, result) => {
        var level = result.districtList[0].level
        var bounds = result.districtList[0].boundaries
        if (!this.districtOverlayGroups[level]) {
          this.districtOverlayGroups[level] = new AMap.OverlayGroup()
          this.districtOverlayGroups[level].overlayCount = 0
        }
        var overlayCount = this.districtOverlayGroups[level].overlayCount
        this.districtOverlayGroups[level].overlayCount = overlayCount + 1
        var fileColor = this.getDistrictGroupColor(overlayCount)
        var fillOpacity = 0.8
        if (level === 'district') fillOpacity = 0.9
        bounds.forEach((bound) => {
          var overlayer = new AMap.Polyline({
            path: bound,
            // strokeColor: '#1890ff',
            strokeColor: fileColor,
            // strokeColor: "#F5E301",
            fillOpacity: fillOpacity,
            fillColor: fileColor,
            strokeWeight: 2
          })
          this.districtOverlayGroups[level].addOverlay(overlayer)
        })
        /*     var adbText =  new AMap.Text({
               text: result.districtList[0].name,
               anchor: 'center', // 设置文本标记锚点
               draggable: false,
               cursor: 'pointer',
               style: {
                 fontSize: 20,
                 border: 0,
                 fontWeight: 'bold',
                 background: 'none',
                 strokeColor: '#c67805'
               },
               position: result.districtList[0].center
             });
             this.districtOverlayGroups[level].addOverlay(adbText)*/
        this.districtOverlayGroups[level].overlayCount = overlayCount + 1
        this.dictictCount--
        if (level !== 'district') {
          var districtListNext = result.districtList[0].districtList
          if (districtListNext) {
            this.dictictCount = this.dictictCount + result.districtList[0].districtList.length
            districtListNext.forEach((citDistrict) => {
              this._initDistrictOverLayGroup(citDistrict.adcode)
            })
          }
        }
        if (!this.firstDisrtictLevel) { //第一次设置初始化视野
          this.firstDisrtictLevel = level
          if (this.mapDbConfig.fitViewType === 1) {
            if (!this.map) return
            this.map.add(this.districtOverlayGroups[level])
            this.districtOverlayGroups[level].isAdded = true
            this.setMapFitView(this.districtOverlayGroups[this.firstDisrtictLevel].getOverlays())
          }
        }
      })
    },
    districtGroupZoomChange () {
      if (!this.map) return
      let currentZoom = this.map.getZoom()
      let hideLevels = []
      let showLevels = []
      for (let level in this.districtOverlayGroups) {
        let distGroupConfig = this.getDistrictGroupConfig(level)
        if (this.firstDisrtictLevel === level) { //如果是第一级别
          if (currentZoom > distGroupConfig.maxZoom) {
            hideLevels.push(level)
          } else {
            if (!this.districtOverlayGroups[level].isAdded) {
              this.map.add(this.districtOverlayGroups[level])
              this.districtOverlayGroups[level].isAdded = true
            }
            showLevels.push(level)
          }
        } else if (distGroupConfig.minZoom <= currentZoom && currentZoom <= distGroupConfig.maxZoom) {
          if (!this.districtOverlayGroups[level].isAdded) {
            this.map.add(this.districtOverlayGroups[level])
            this.districtOverlayGroups[level].isAdded = true
          }
          showLevels.push(level)
        } else {
          hideLevels.push(level)
        }
      }
      showLevels.forEach((showLevel) => {
        this.districtOverlayGroups[showLevel].show()
      })
      hideLevels.forEach((hideLevel) => {
        this.districtOverlayGroups[hideLevel].hide()
      })
    },
    mapZoomchange () {
      if (this.monitoringPointMapZoomChange) {
        this.monitoringPointMapZoomChange()
      }
      if (this.removeOverMarkTips) {
        this.removeOverMarkTips()
      }
      this.districtGroupZoomChange()
      var curentZoom = this.map.getZoom()
      if (this.isLoadRoadNetLayer) {
        if (curentZoom < 6 || curentZoom > 12) {
          this.roadNetLayer.show()
        } else {
          this.roadNetLayer.hide()
        }
      }
    }
  }
}

export default common
