<template>
  <div class="map-cover" style="width: 100%; height: 100%;">
    <div :id="mapContainerId" class="map-container"></div>
    <div class="form-search-container">
      <a-input-search class="ant-modal-search"
                      v-model="q" enterButton size="large"
                      :loading="searchStatus" placeholder="请输入搜索关键字" @search="onSearch">
        <a-select slot="addonBefore" v-if="this.leftDropData.length > 1"
                  @change="leftDropChange" v-model="leftDropChecked" style="width: 90px; font-size: 16px;">
          <a-select-option :key="item.value" :value="item.value" v-for="item in leftDropData">{{ item.label }}
          </a-select-option>
        </a-select>
      </a-input-search>
      <div class="form-container-body">
        <SearchCarList ref="carRef" v-if="leftDropChecked === 'car'"
                       :searchKey="q"
                       @onItemClick="formItemClick"
                       @onSearchStart="searchStartHandle"
                       @onSearchSuccess="searchSuccessHandle"
                       @onSearchError="searchErrorHandle"/>
        <SearchDriverList ref="driverRef" v-if="leftDropChecked === 'driver'"
                          :searchKey="q"
                          @onSearchStart="searchStartHandle"
                          @onSearchSuccess="searchSuccessHandle"
                          @onSearchError="searchErrorHandle"
                          @onItemClick="formItemClick"/>
        <SearchSpotList ref="spotRef" v-if="leftDropChecked === 'spot'"
                        :searchKey="q"
                        @onItemClick="formItemClick"
                        @onSearchStart="searchStartHandle"
                        @onSearchSuccess="searchSuccessHandle"
                        @onSearchError="searchErrorHandle"/>
      </div>
    </div>
    <spot-detail ref="spotDetailRef" v-if="rightFormChecked === 'spot'" @onSpotFixed="spotFixed"
                 v-model="rightFormShow" @close="closeRightForm"/>
    <car-detail ref="carDetailRef" v-if="rightFormChecked === 'car'"
                v-model="rightFormShow"
                @close="closeRightForm"/>
    <DriverDetail ref="carDetailRef" v-if="rightFormChecked === 'driver'"
                  v-model="rightFormShow" @close="closeRightForm"/>
    <area-detail ref="areaDetailRef" v-if="rightFormChecked === 'area'" @onSpotFixed="spotFixed"
                 v-model="rightFormShow" @close="closeRightForm"/>
    <a-dropdown v-model="areaTreeVisible" :trigger="['click']">
      <div class="drop-container" style="width:80px;right: 200px;border-radius:6px 0px 0px 6px;">
        <div class="ant-modal-title drop-container-title">
          <a-spin :spinning="loadDistrictLayerData">
            <div class="default-hover cursor-pointer">
              <span>行政区</span>
            </div>
          </a-spin>
        </div>
      </div>
      <div slot="overlay"
           style="max-height: 700px;overflow: auto; padding: 10px; background: white;width: 200px;background-clip: padding-box;border-radius: 4px;outline: none;box-shadow: 0 2px 8px rgba(0,0,0,.15);-webkit-transform: translateZ(0);">
        <a-tree
          :replaceFields="{
          children:'children', title:'label', key:'value'
          }"
          v-model="areaTreeKey"
          :tree-data="districtList"
          :expanded-keys="areaTreeExpandedKeys"
          :defaultSelectedKeys="areaTreeDefaultSelectedKeys"
          @expand="onExpand"
          @select="treeSelect"
        />
      </div>
    </a-dropdown>
    <!--<a-dropdown v-model="dropdpwnVisible" :trigger="['click']">
      <div class="drop-container" style="width:80px;right: 120px;border-radius:0;">
        <div class="ant-modal-title drop-container-title">
          <a-spin :spinning="loadLayerData">
            <div class="default-hover cursor-pointer">
              <span>图层</span>
            </div>
          </a-spin>
        </div>
      </div>
      <div slot="overlay"
           style="padding: 10px; background: white;width: 470px;background-clip: padding-box;border-radius: 4px;outline: none;box-shadow: 0 2px 8px rgba(0,0,0,.15);-webkit-transform: translateZ(0);">
        <div class="areaTable-top">任务状态:
          <a-select size="small" :value="qyType" style="width: 100px" @change="handleQyType">
            <a-select-option value="task">
              有任务的
            </a-select-option>
            <a-select-option value="noTask">
              无任务的
            </a-select-option>
            <a-select-option value="ALL">
              所有
            </a-select-option>
          </a-select>
          <a-radio-group style="margin-left: 10px" v-if="qyType==='task'" buttonStyle="solid" @change="handleCheckLayerType" v-model="checkLayer" size="small">
            <a-radio-button v-for="item in layerType" :key="item.value" :value="item.value">{{item.label}}</a-radio-button>
          </a-radio-group>
        </div>
        <div class="areaTable-top">集污点类型:
          <a-radio-group buttonStyle="solid" @change="handleGatherSpotType" v-model="gatherSpot" size="small">
            <a-radio-button v-for="item in gatherSpotType" :key="item.value" :value="item.value">{{item.label}}</a-radio-button>
          </a-radio-group>
        </div>
        <div class="areaTable-top">
          <a-button type="primary" @click="closeLayerWin" size="small" style="float:right">关闭</a-button>
          <br>
        </div>
      </div>
    </a-dropdown>-->
    <a-dropdown v-model="dropdpwnVisible" :trigger="['click']">
      <div class="drop-container" style="width:80px;right: 120px;border-radius:0;">
        <div class="ant-modal-title drop-container-title">
          <a-spin :spinning="loadLayerData">
            <div class="default-hover cursor-pointer">
              <span>图层</span>
            </div>
          </a-spin>
        </div>
      </div>
      <div slot="overlay"
           style="background: white;width: 520px;background-clip: padding-box;border-radius: 4px;outline: none;box-shadow: 0 2px 8px rgba(0,0,0,.15);-webkit-transform: translateZ(0);">
        <a-card :headStyle="{fontWeight:'bolder'}" :bodyStyle="{padding:'0 12px'}" title="集污点" style="width: 100%" size="small" :bordered="false">
          <div class="areaTable-top">任务状态:
            <a-select size="small" :value="qyType" style="width: 100px" @change="handleQyType">
              <a-select-option value="task">
                有任务的
              </a-select-option>
              <a-select-option value="noTask">
                无任务的
              </a-select-option>
              <a-select-option value="ALL">
                所有
              </a-select-option>
            </a-select>
            <a-radio-group style="margin-left: 10px" v-if="qyType==='task'" buttonStyle="solid" @change="handleCheckLayerType" v-model="checkLayer" size="small">
              <a-radio-button v-for="item in layerType" :key="item.value" :value="item.value">{{item.label}}</a-radio-button>
            </a-radio-group>
          </div>
          <div class="areaTable-top">集污点类型:
            <a-radio-group buttonStyle="solid" @change="handleGatherSpotType" v-model="gatherSpot" size="small">
              <a-radio-button v-for="item in gatherSpotType" :key="item.value" :value="item.value">{{item.label}}</a-radio-button>
            </a-radio-group>
          </div>
        </a-card>
        <a-card :headStyle="{fontWeight:'bolder'}" :bodyStyle="{padding:'0 5px'}" title="行政区" style="width: 100%;margin-top: 10px" size="small" :bordered="false">
          <a-radio-group class="areaTable-top" buttonStyle="solid" @change="handAreaType" v-model="areaTypeValue" size="small">
            <a-radio-button v-for="item in areaType" :key="item.value" :value="item.value">{{item.label}}</a-radio-button>
          </a-radio-group>
        </a-card>
        <a-card :headStyle="{fontWeight:'bolder'}" :bodyStyle="{padding:'0 5px'}" title="车辆" style="width: 100%;margin-top: 10px" size="small" :bordered="false">
          <a-radio-group class="areaTable-top" buttonStyle="solid" @change="handleCarType" v-model="carTypeValue" size="small">
            <a-radio-button v-for="item in carType" :key="item.value" :value="item.value">{{item.label}}</a-radio-button>
          </a-radio-group>
        </a-card>
        <div class="areaTable-top">
          <a-button type="primary" @click="closeLayerWin" size="small" style="float:right">关闭</a-button>
          <br>
        </div>
      </div>
    </a-dropdown>
    <a-dropdown>
      <div class="drop-container" style="width:80px;right: 40px;border-radius:0px 6px 6px 0px;">
        <div class="ant-modal-title drop-container-title">
          <template v-for="item in mapStyleItems">
            <div class="default-hover cursor-pointer">
              <span v-if="item.value === chooseMapStyle">  {{ item.label }}</span>
            </div>
          </template>
        </div>
      </div>
      <a-menu slot="overlay">
        <a-menu-item v-for="item in mapStyleItems" :key="item.value">
          <a target="_blank" @click="chooseMapStyleHandle(item)">{{ item.label }} &nbsp;&nbsp;
            <a-icon v-if="chooseMapStyle === item.value" type="check" class="f-r"
                    style="color: #1e88e5; margin-top: 4px;"/>
          </a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <Statistics style="bottom: 40px; position: fixed; z-index: 900; margin-left:80px;"></Statistics>
    <a-popover placement="topLeft" class="bottom" style="margin-left:10px;" trigger="click" :visible="tlShow">
      <div slot="content">
        <a-row style="width: 120px;">
          <a-col :span="24"><img src="https://sosfile.fangshuoit.com/o_1ec254c721l1n52rt4rdd9s879.png"><span class="title">集污池</span></a-col>
          <a-col :span="24"><img src="https://sosfile.fangshuoit.com/o_1efttl4ca1lo2fvq54aqtev971c.png"><span class="title">三格化粪池</span></a-col>
          <a-col :span="24"><img src="https://sosfile.fangshuoit.com/o_1ec257ofroi51rk916c71s351t7k1h.png"><span class="title">一体化泵站</span></a-col>
          <a-col :span="24"><img src="https://sosfile.fangshuoit.com/o_1ec258ru2rtq1q69fl51oki112i1t.png"><span class="title">污水处理厂</span></a-col>
          <a-col :span="24"><img src="http://assets.boze-tech.com/xian.png"><span class="title">县</span></a-col>
          <a-col :span="24"><img src="http://assets.boze-tech.com/zhen.png"><span class="title">镇</span></a-col>
          <a-col :span="24"><img src="http://assets.boze-tech.com/cun.png"><span class="title">村</span></a-col>
        </a-row>
      </div>
      <a-button style="color: black;font-weight: bold;bottom: 40px; position:fixed; z-index: 900; margin-left:15px;" @click="handleHoverChange">图例</a-button>
    </a-popover>
  </div>
</template>

<script>
  import common from '../mixins/common'
  // import layer from '../mixins/layer'
  import mapInit from '../mixins/map-init'
  import UUID from '../../../../utils/UUID'
  import SERVICE_URLS from '../../../../api/service.url'
  import car from '../mixins/car'
  import spot from '../mixins/spot'
  import area from '../mixins/area'
  import sewagePlant from '../mixins/sewage-plant'
  import SearchSpotList from './SearchSpotList'
  import SearchCarList from './SearchCarList'
  import SearchDriverList from './SearchDriverList'
  import SpotDetail from './SpotDetail'
  import CarDetail from './CarDetail'
  import Statistics from '../statistics/Statistics'
  import DriverDetail from './DriverDetail'
  import AreaDetail from './AreaDetail'

  export default {
    name: 'Cover',
    mixins: [common, car, spot, sewagePlant, mapInit, area],
    components: {
      DriverDetail,
      Statistics,
      SpotDetail,
      SearchDriverList,
      SearchCarList,
      SearchSpotList,
      CarDetail,
      AreaDetail
    },
    data () {
      return {
        searchStatus: false,
        mapContainerId: new UUID().id,
        map: null,
        /*登记报抽*/
        addModal: false,
        /*集污点检索*/
        searchContent: '',
        keySearchData: [],
        countCarsData: {},
        countDriversData: {},
        countSpotData: {},
        formShow: false,
        searchKey: '',
        checkCover: 'marker',
        marker: null,
        polyline: null,
        textMarker: null,
        polyEditor: null,
        polygon: null,
        chooseData: {},
        instance: {},
        mouseTool: null,
        type: 'edit',
        chooseMapStyle: 'normal',
        mapStyleItems: [
          {
            value: 'normal',
            label: '标准'
          },
          {
            value: 'dark',
            label: '幻影黑'
          },
          {
            value: 'light',
            label: '月光银'
          },
          {
            value: 'whitesmoke',
            label: '远山黛'
          },
          {
            value: 'fresh',
            label: '草色青'
          },
          {
            value: 'grey',
            label: '雅士灰'
          },
          {
            value: '8ea104d16c14b242522f70fbda71927c',
            label: '自定义'
          },
          {
            value: 'graffiti',
            label: '涂鸦'
          },
          {
            value: 'macaron',
            label: '马卡龙'
          },
          {
            value: 'darkblue',
            label: '靛青蓝'
          },
          {
            value: 'wine',
            label: '酱籽'
          },
          {
            value: 'satellite',
            label: '卫星图'
          }
        ],
        tlShow: false,
        loadDistrictLayerData: false,
        selectedMarker: null
      }
    },
    created () {
      this.getAreaTree()
    },
    destroyed () {
      if (this.map) {
        this.map.destroy()
        this.map = null
      }
      if (!window.dpInit) {
        window.AMap = null
      }
    },
    methods: {
      handleHoverChange () {
        this.tlShow = !this.tlShow
      },
      init () {
        this.map = new AMap.Map(this.mapContainerId, {
          mapStyle: 'amap://styles/normal',
          resizeEnable: true,
          features: ['bg', 'road', 'building', 'point'],
          center: [106.230909, 38.487193],
          zoom: 11
        })
        this.drawDistrictBorder()
        this.buildMapOtherLayer()
        this.getAllCarInfo()
        this.getAllSpot()
        this.getSpot()
        this.getSewagePlant()
        this.loadAreaList()
      },
      // 关闭form
      closeForm () {
        this.formShow = false
        // this.clearOverlay()
        // this.chooseData = {}
        // this.instance = {}
        // this.checkCover = 'none'
      },
      // 抓取服务端数据
      selectItem (type, id, name, coverType) {
        this.getCurrentFormRef().showTextMemo = false
        // 先查询是否已存在与页面coverItems中
        const coverObj = this.findCoverByInstanceId(id, type)
        if (coverObj) {
          this.coverClick(coverObj.cover[0])
        } else {
          this.checkCover = coverType
          this.initCover()
          this.$nextTick(() => {
            this.getCurrentFormRef().setLoading(true)
            // this.showTextMemo = false
          })
          this.chooseData = {
            systemModule: type,
            instanceId: id,
            title: name
          }
          let url = SERVICE_URLS.overlay.viewByInstance
          let data = {
            instance: this.chooseData.instanceId,
            module: this.chooseData.systemModule
          }
          let params = {}
          if (this.chooseData.systemModule === 'base') {
            url = SERVICE_URLS.overlay.view
            params['id'] = this.chooseData.instanceId
            data = {}
            this.chooseData['systemModule'] = undefined
          }
          this.formShow = true
          this.$http(this, {
            url: url,
            data: data,
            params: params,
            loading: 'no',
            noTips: true,
            success: (data) => {
              this.getCurrentFormRef().setLoading(false)
              if (data.body) {
                //this.instance = data.body
                //this.formShow = true
                //this.showDataToMap()
                this.addCoverToMapView(data.body)
              } else {
                // this.formShow = false
                this.$nextTick(() => {
                  this.getCurrentFormRef().setTitle(this.chooseData.title)
                  // 进入绘制
                  this.startDrawCover()
                })
              }
            },
            error: () => {
              this.getCurrentFormRef().setLoading(false)
            }
          })
        }
      },

      //画行政区域边界
      drawDistrictBorder () {
        let self = this
        //加载行政区划插件
        var district = null
        var opts = {
          subdistrict: 0, //获取边界不需要返回下级行政区
          extensions: 'all', //返回行政区边界坐标组等具体信息
          level: 'district' //查询行政级别为 区县
        }
        district = new AMap.DistrictSearch(opts)
        //设置行政区
        district.search('中宁县', function (status, result) {
          var polygons = []
          var bounds = result.districtList[0].boundaries
          if (bounds) {
            for (var i = 0, l = bounds.length; i < l; i++) {
              //生成行政区划polygon
              var polygon = new AMap.Polygon({
                strokeWeight: 3,
                strokeColor: '#1890ff',
                path: bounds[i],
                fillColor: '#1890ff',
                zIndex: 20,
                fillOpacity: 0.1
              })
              polygons.push(polygon)
            }
          }
          self.map.add(polygons)
          self.map.setFitView(polygons)
        })
      },

      initCover () {
        this.instance = {}
        this.mouseTool.close(true)
        this.closePolygonEdit()
        this.closePolylineEdit()
        this.closeOverlay()
        this.map.off('click', this.mapClick)
      },

      // 开始绘制 页面上已存在的状态需要关闭，增加对应操作事件
      startDrawCover () {
        const formData = this.getCurrentFormRef().getFormData()
        if (this.checkCover === 'polyline') {
          this.mouseTool.polyline(formData)
        } else if (this.checkCover === 'polygon') {
          this.mouseTool.polygon(formData)
        } else if (this.checkCover === 'marker' || this.checkCover === 'text') {
          this.map.on('click', this.mapClick)
        }
      },
      showDataToMap () {
        if (this.instance.id) {
          this.checkCover = this.instance.type
          this.$nextTick(() => {
            this.getCurrentFormRef().setEditData(this.instance)
          })
        }
      },

      // 获取当前显示的Form引用
      getCurrentFormRef () {
        return this.$refs[this.checkCover + 'FormRef']
      },
      addCover (value) {
        this.chooseData['systemModule'] = undefined
        this.initCover()
        if (value === this.checkCover) {
          this.getCurrentFormRef().resetForm()
        }
        this.checkCover = value
        this.formShow = true
        this.$nextTick(() => {
          this.startDrawCover()
        })
      },
      // 在地图上移除当前选中覆盖物
      clearOverlay () {
        this.closePolygonEdit()
        this.closePolylineEdit()
        if (this.marker) {
          this.marker.setMap(null)
          this.marker = null
        }
        if (this.polyline) {
          this.polyline.setMap(null)
          this.polyline = null
        }
        if (this.polygon) {
          this.polygon.setMap(null)
          this.polygon = null
        }
        if (this.textMarker) {
          this.textMarker.setMap(null)
          this.textMarker = null
        }
      },
      mapClick (e) {
        if (this.checkCover === 'marker' || this.checkCover === 'text') {
          this.$refs[this.checkCover + 'FormRef'].setPosition('[ ' + e.lnglat.getLng() + ',' + e.lnglat.getLat() + ' ]')
          if (this.checkCover === 'text') {
            this.onTextChange()
          } else {
            this.onMarkerChange()
          }
          this.formShow = true
          this.map.off('click', this.mapClick)
        }
      },
      mapDraw (e) {
        const path = []
        e.obj.getPath().forEach((x) => {
          path.push('[ ' + x.lng + ',' + x.lat + ' ]')
        })
        if (this.checkCover === 'polyline') {
          if (this.$refs['polylineFormRef']) {
            this.$refs['polylineFormRef'].setPolylinePosition(path)
            this.onPolylineChange()
          }
        } else if (this.checkCover === 'polygon') {
          if (this.$refs['polygonFormRef']) {
            this.$refs['polygonFormRef'].setPolygonPosition(path)
            this.onPolygonChange()
          }
        }
        this.formShow = true
        this.mouseTool.close(true)
      },
      onMarkerChange (type) {
        // 获取 markerFormRef 中保存的marker信息，并设置在地图
        this.$nextTick(() => {
          if (this.marker) {
            this.marker.setMap(null)
          }
          const markerInfo = this.$refs['markerFormRef'].getFormData()
          markerInfo.draggable = true
          this.marker = new AMap.Marker(markerInfo)
          this.marker.setMap(this.map)
          this.marker.on('dragend', this.markerDragend)
          this.marker.on('click', () => {
            this.formShow = true
          })
          if (type === 'edit') {
            if (!this.map) return
            this.map.setFitView(this.marker)
          }
        })
      },
      onPolylineChange (type) {
        this.$nextTick(() => {
          const polylineInfo = this.$refs['polylineFormRef'].getFormData()
          if (this.polyline) {
            this.polyline.setMap(null)
          }

          this.polyline = new AMap.Polyline(polylineInfo)
          this.polyline.setMap(this.map)
          this.polyline.on('dragend', this.polylineDragend)
          this.polyline.on('click', this.openPolylineEdit)
          this.polyline.on('rightclick', this.closePolylineEdit)

          if (type === 'edit') {
            if (!this.map) return
            this.map.setFitView(this.polyline)
          }
        })
      },
      onPolygonChange (type) {
        this.$nextTick(() => {
          const polygonInfo = this.$refs['polygonFormRef'].getFormData()
          if (this.polygon) {
            this.polygon.setMap(null)
          }

          if (polygonInfo.path.length > 2) {
            // 多边形至少3个点
            this.polygon = new AMap.Polygon(polygonInfo)
            this.polygon.setMap(this.map)
            this.polygon.on('dragend', this.polygonDragend)
            this.polygon.on('click', this.openPolygonEdit)
            this.polygon.on('rightclick', this.closePolygonEdit)
          }

          if (type === 'edit') {
            if (!this.map) return
            this.map.setFitView(this.polyline)
          }
        })
      },
      onTextChange (type) {
        this.$nextTick(() => {
          if (this.textMarker) {
            this.textMarker.setMap(null)
          }
          const textInfo = this.$refs['textFormRef'].getFormData()
          textInfo.draggable = true
          this.textMarker = this.buildTextMarker(textInfo)
          this.textMarker.setMap(this.map)
          this.textMarker.on('dragend', this.textDragend)
          this.textMarker.on('click', () => {
            this.formShow = true
          })
          if (type === 'edit') {
            if (!this.map) return
            this.map.setFitView(this.textMarker)
          }
        })
      },
      openPolygonEdit () {
        this.polygonEdit(true)
      },
      closePolygonEdit () {
        this.polygonEdit(false)
      },
      openPolylineEdit () {
        this.polylineEdit(true)
      },
      closePolylineEdit () {
        this.polylineEdit(false)
      },
      // Form 变化回调
      onMarkerValuesChange (values) {
        const markerInfo = this.$refs['markerFormRef'].getFormData()
        if (markerInfo.position) {
          if (this.marker) {
            // if (values['title'] || values['labelFontColor']
            //   || values['labelFontSize'] || values['labelOffset']
            //   || values['showTextMemo'] === true) {
            //   this.marker.setLabel(markerInfo.label)
            // }
            // if (values['showTextMemo'] === false) {
            //   this.marker.setLabel(null)
            // }
            this.marker.setContent(this.buildMarkerContent(markerInfo, this.instance.id, 'show'))
            if (markerInfo.showTextMemo === true) {
              this.marker.setLabel(markerInfo.label)
            } else {
              this.marker.setLabel(null)
            }
            this.marker.setPosition(markerInfo.position)
            this.map.setCenter(markerInfo.position)
          } else {
            this.onMarkerChange()
          }
        }
      },
      // 标线 Form变化回调
      onPolylineValuesChange (values) {
        // if (values['path'] && this.polyline) {
        //   if (values['path'].length > 1) {
        //     this.polyline.setPath(values['path'])
        //   } else {
        //     this.polyline.setMap(null)
        //     this.polyline = null
        //   }
        // } else if (values['isOutline'] || values['borderWeight']
        //   || values['outlineColor'] || values['strokeColor']
        //   || values['strokeOpacity'] || values['strokeWeight']
        //   || values['strokeStyle'] || values['strokeDasharray']
        //   || values['lineJoin'] || values['lineCap'] || (values['showDir'] === true || values['showDir'] === false)) {
        //   const polylineInfo = this.$refs['polylineFormRef'].getFormData()
        //   if (polylineInfo.path && polylineInfo.path.length > 1 && this.polyline) {
        //     this.polyline.setOptions(polylineInfo)
        //   }
        // }
        const polylineInfo = this.$refs['polylineFormRef'].getFormData()
        if (polylineInfo.path && polylineInfo.path.length > 1 && this.polyline) {
          this.polyline.setOptions(polylineInfo)
        }
      },
      // 多面体 Form变化回调
      onPolygonValuesChange (values) {
        // if (values['path'] && this.polygon) {
        //   if (values['path'].length > 2) {
        //     this.polygon.setPath(values['path'])
        //   } else {
        //     this.polygon.setMap(null)
        //     this.polygon = null
        //   }
        // } else if (values['strokeColor'] || values['strokeOpacity']
        //   || values['strokeWeight'] || values['strokeStyle']
        //   || values['strokeDasharray'] || values['fillColor'] || values['fillOpacity']) {
        //   const polygonInfo = this.$refs['polygonFormRef'].getFormData()
        //   if (polygonInfo.path && polygonInfo.path.length > 2 && this.polygon) {
        //     this.polygon.setOptions(polygonInfo)
        //   }
        // }
        const polygonInfo = this.$refs['polygonFormRef'].getFormData()
        if (polygonInfo.path && polygonInfo.path.length > 2 && this.polygon) {
          this.polygon.setOptions(polygonInfo)
        }
      },
      onTextValuesChange () {
        if (this.textMarker) {
          const textInfo = this.$refs['textFormRef'].getFormData()
          this.textMarker.setText(textInfo.text)
          this.textMarker.setStyle(this.buildTextMarkerStyle(textInfo))
          this.textMarker.setPosition(textInfo.position)
        }
      },
      polylineEdit (state) {
        if (state) {
          this.formShow = true
        }
        if (this.polyline) {
          const options = this.polyline.getOptions()
          options.draggable = state
          this.polyline.setOptions(options)
        }
        if (state && this.polyline && !this.polyEditor) {
          this.polyEditor = new AMap.PolyEditor(this.map, this.polyline)
          this.polyEditor.on('addnode', this.polylinePathChange)
          this.polyEditor.on('adjust', this.polylinePathChange)
          this.polyEditor.on('removenode', this.polylinePathChange)
          this.polyEditor.open()
        } else if (!state && this.polyEditor) {
          this.polyEditor.close()
          this.polyEditor = null
        }
      },

      polylinePathChange (event) {
        const temp = JSON.parse(this.instance.options)
        const path = []
        this.polyline.getPath().forEach((x) => {
          path.push([x.lng, x.lat])
        })
        temp['path'] = path
        this.instance.options = JSON.stringify(temp)
        this.polylineDragend()
      },

      polygonEdit (state) {
        if (state) {
          this.formShow = true
        }
        if (this.polygon) {
          const options = this.polygon.getOptions()
          options.draggable = state
          this.polygon.setOptions(options)
        }
        if (state && this.polygon && !this.polyEditor) {
          this.polyEditor = new AMap.PolyEditor(this.map, this.polygon)
          this.polyEditor.on('addnode', this.polygonPathChange)
          this.polyEditor.on('adjust', this.polygonPathChange)
          this.polyEditor.on('removenode', this.polygonPathChange)
          this.polyEditor.open()
        } else if (!state && this.polyEditor) {
          this.polyEditor.close()
          this.polyEditor = null
        }
      },

      polygonPathChange (event) {
        const temp = JSON.parse(this.instance.options)
        const path = []
        this.polygon.getPath().forEach((x) => {
          path.push([x.lng, x.lat])
        })
        temp['path'] = path
        this.instance.options = JSON.stringify(temp)
        this.polygonDragend()
      },

      // marker 拖动回调
      markerDragend (e) {
        this.$refs['markerFormRef'].setPosition('[ ' + e.lnglat.getLng() + ',' + e.lnglat.getLat() + ' ]')
      },
      polylineDragend (e) {
        const path = []
        this.polyline.getPath().forEach((x) => {
          path.push('[ ' + x.lng + ',' + x.lat + ' ]')
        })
        if (this.$refs['polylineFormRef']) {
          this.$refs['polylineFormRef'].setPolylinePosition(path)
        }
      },
      polygonDragend (e) {
        const path = []
        this.polygon.getPath().forEach((x) => {
          path.push('[ ' + x.lng + ',' + x.lat + ' ]')
        })
        if (this.$refs['polygonFormRef']) {
          this.$refs['polygonFormRef'].setPolygonPosition(path)
        }
      },
      textDragend (e) {
        this.$refs['textFormRef'].setPosition('[ ' + e.lnglat.getLng() + ',' + e.lnglat.getLat() + ' ]')
      },
      addCoverToMapView (values) {
        const { coverItemsAdd, setCoverToMap, coverClick, map } = this
        const coverObj = coverItemsAdd(values)
        setCoverToMap(coverObj, map)
        coverClick(coverObj.cover[0])
      },
      // 表单数据变化
      addSuccessHandle (values) {
        this.addCoverToMapView(values)
        this.onSearchNotRestPage(this.monitoringPointsAction)
      },
      editSuccessHandle (values) {
        if (this.checkCover === 'polyline') {
          this.onPolylineValuesChange(values)
        } else if (this.checkCover === 'polygon') {
          this.onPolygonValuesChange()
        } else if (this.checkCover === 'marker') {
          this.onMarkerValuesChange()
        } else if (this.checkCover === 'text') {
          this.onTextValuesChange()
        }
        this.instance = values
        // 使用了图层方式
        if (this.checkLayer !== 'none') {
          const cover = this.findCoverByKey(values.id)
          cover.instance = values
        }
        eval('this.on' + this.checkCover.charAt(0).toUpperCase() + this.checkCover.slice(1) + 'ValuesChange()')
        // 基础更新需要设置组件左边组件中的 name
        if (this.tempInstanceType === 'base') {
          this.$refs['baseFormRef'].setNameById(this.instance.name, this.instance.id)
        }
      },
      monitoringPointsAction () {
        let action = null
        if (this.tempInstanceType === 'monitoring_point') {
          action = 'doNothing'
        } else {
          action = null
        }
        return action
      },
      deleteSuccessHandle () {
        this.onSearchNotRestPage(this.monitoringPointsAction)
        this.removeCoverByKey(this.instance.instanceId)
        this.instance = {}
        this.clearOverlay()
        this.formShow = false
      },
      coverClick (target) {
        this.closeOverlay()
        const cover = this.findCoverByKey(target.getExtData()['key'])
        this.instance = cover.instance
        this.chooseData = {
          systemModule: cover.instance.systemModule,
          instanceId: cover.instance.id,
          title: cover.instance.name
        }
        this.checkCover = cover.type
        if (cover.type === 'marker') {
          this.marker = cover.cover[0]
          this.marker.on('dragend', this.markerDragend)
          this.marker.setDraggable(true)
          this.setSelectMarkerAnimation(this.marker)
        } else if (cover.type === 'polyline') {
          this.polyline = cover.cover[0]
          this.openPolylineEdit()
          this.polyline.on('dragend', this.polylineDragend)
          this.polyline.on('click', this.openPolylineEdit)
          this.polyline.on('rightclick', this.closePolylineEdit)
        } else if (cover.type === 'polygon') {
          this.polygon = cover.cover[0]
          this.openPolygonEdit()
          this.polygon.on('dragend', this.polygonDragend)
          this.polygon.on('click', this.openPolygonEdit)
          this.polygon.on('rightclick', this.closePolygonEdit)
        } else if (cover.type === 'text') {
          this.textMarker = cover.cover[0]
          this.textMarker.on('dragend', this.textDragend)
          this.textMarker.setDraggable(true)
        }
        this.formShow = true
        this.$nextTick(() => {
          this.getCurrentFormRef().setEditData(this.instance, 'noCallback')
        })
        if (cover.type === 'text' || cover.type === 'marker') {
          this.map.setCenter(cover.cover[0].getPosition())
        } else {
          if (!this.map) return
          const zoom = this.map.getZoom()
          this.map.setFitView(cover.cover[0])
          this.map.setZoom(zoom)
        }
      },
      closeOverlay () {
        if (this.marker) {
          this.marker.off('dragend', this.markerDragend)
          this.marker.setDraggable(false)
          this.removeSelectMarkerAnimation(this.marker)
          if (this.marker.getExtData() && !this.marker.getExtData()['key']) {
            this.marker.setMap(null)
          }
          this.marker = null
        }
        if (this.polyline) {
          this.closePolylineEdit()
          this.polyline.off('dragend', this.polylineDragend)
          this.polyline.off('click', this.openPolylineEdit)
          this.polyline.off('rightclick', this.closePolylineEdit)
          if (this.polyline.getExtData() && !this.polyline.getExtData()['key']) {
            this.polyline.setMap(null)
          }
          this.polyline = null
        }
        if (this.polygon) {
          this.closePolygonEdit()
          this.polygon.off('dragend', this.polygonDragend)
          this.polygon.off('click', this.openPolygonEdit)
          this.polygon.off('rightclick', this.closePolygonEdit)
          if (this.polygon.getExtData() && !this.polygon.getExtData()['key']) {
            this.polygon.setMap(null)
          }
          this.polygon = null
        }
        if (this.textMarker) {
          if (this.textMarker.getExtData() && !this.textMarker.getExtData()['key']) {
            this.textMarker.setMap(null)
          }
          this.textMarker = null
        }
        this.mouseTool.close()
      },
      // 图片上传成功
      markerIconUploadSuccess () {
        if (this.checkCover === 'marker' && this.marker) {
          const markerInfo = this.$refs['markerFormRef'].getFormData()
          this.marker.setContent(this.buildMarkerContent(markerInfo, this.instance.id))
          this.setSelectMarkerAnimation(this.marker)
        }
      },
      chooseMapStyleHandle (item) {
        this.chooseMapStyle = item.value
        if (this.map) {
          if (this.chooseMapStyle === 'satellite') {
            this.map.add([this.getSatelliteLayer()])
          } else {
            this.map.remove([this.getSatelliteLayer()])
            this.map.setMapStyle('amap://styles/' + item.value)
          }
        }
      },
      getSatelliteLayer () {
        if (!this.satelliteLayer) {
          this.satelliteLayer = new AMap.TileLayer.Satellite()
        }
        return this.satelliteLayer
      },
      closeLayerWin () {
        this.dropdpwnVisible = false
      },
      setSelectedMarkerStyle (lnglat) {
        if (!this.selectedMarker) {
          this.selectedMarker = new AMap.Marker({ content: this.getSeletedMarkerContext(), map: this.map })
        }
        this.selectedMarker.setPosition(lnglat)
        this.selectedMarker.setOffset(new AMap.Pixel(-5, -5))
      },
      getSeletedMarkerContext () {
        return '<div class="marker-animation marker-animation-C945B14032200001C8C01EA61B541B64-505" style="right: 24px; bottom: 24px; width: 80px; height: 80px; background-color: rgb(45, 140, 240);"></div>'
      }
    }
  }
</script>
<style scoped>
  .bottom {
    bottom: 40px;
    position: fixed;
    z-index: 10;
    margin-left: 90px;
  }

  .right {
    right: 0;
    bottom: 10px;
    height: 40px;
    width: 48px;
    padding: 0;
  }

  .title {
    color: black;
    font-weight: bold;
    margin-left: 10px;
  }

  img {
    width: 20px;
    height: 20px;
  }

  .areaTable {
    width: 200px;
  }

  .areaTable-top {
    font-weight: 700;
    width: 100%;
    vertical-align: top;
    padding: 10px 0;
  }

  .areaTable-a {
    white-space: nowrap;
    margin: 10px;
    line-height: 30px;
    color: #3d6dcc;
    text-decoration: none;
    padding: 0 5px;
  }

  .select-area-a {
    color: red;
    border: 1px solid;
    border-radius: 30px;
  }

  .clearCss > a {
    pointer-events: none;
    border: none;
    color: #b4b4b4;
  }
</style>