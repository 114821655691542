<template>
  <a-spin :spinning="spinShow">
    <a-form :form="form">
      <a-row v-if="type==='add'">
        <a-col :span="12">
          <a-form-item label="集污点检索" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-auto-complete style="width: 100%"
                             v-model="searchContent"
                             autoFocus
                             @select="onSelectSpotItem"
                             :dropdown-match-select-width="false"
                             :dropdown-style="{ width: '700px' }"
                             option-label-prop="label"
                             placeholder="请输入集污点关键字">
              <template slot="dataSource">
                <a-select-option v-for="item in keySearchData" :key="item.no" :value="item.id+''" :label="item.name">
                  <a-tag v-if="item.type===spotTypeEnum.DANWENG.value" color="#00BFFF">
                    单翁
                  </a-tag>
                  <a-tag v-if="item.type===spotTypeEnum.JIWUCHI.value" color="#00CD66">
                    集污池
                  </a-tag>
                  <a-tag v-if="item.type===spotTypeEnum.SANGE.value" color="#00CED1">
                    三格化粪池
                  </a-tag>
                  {{item.no}}
                  <a-divider type="vertical"/>
                  {{item.name}}
                  <a-divider type="vertical"/>
                  {{item.areaName}}
                  <a-divider type="vertical"/>
                  {{item.houseNo?item.houseNo:'~'}}
                  <a-divider type="vertical"/>
                  <a-icon type="phone"/>&nbsp;{{item.userPhone}}
                </a-select-option>
              </template>
              <a-input>
                <a-icon slot="suffix" type="search" @click="searchClick"/>
                <a-divider slot="suffix" type="vertical" v-if="this.type === 'add'"/>
                <a-icon slot="suffix" type="sync" @click.stop="restForm" v-if="this.type === 'add'"/>
              </a-input>
            </a-auto-complete>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="集污点名称" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-input v-decorator="['name']" disabled/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="人员地址" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-input v-decorator="['fromAddress', { rules: [{ required: true, message: '地址不能为空' }] }]"
                     placeholder="请输入">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="人员名称" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-input v-decorator="['fromName', { rules: [{ required: true, message: '名称不能为空' }] }]" placeholder="请输入">
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="人员电话" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-input v-decorator="['fromPhone', {
                  rules: [{ type: 'string', pattern: /^1[0-9]{10}$/, required: true, message: '手机号不合法' }],
                  trigger: 'change',
                },]" placeholder="请输入人员电话">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="所属部门" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-tree-select
              v-decorator="['orgId', {initialValue: defaultDeptId, rules: [{ required: true, message: '所属部门不能为空' }] }]"
              :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
              :treeData="orgTree"
              allowClear
              placeholder="请选择，默认当前登录用户所在部门"
            ></a-tree-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="上报时间"
                       :label-col="labelCol"
                       :wrapper-col="wrapperCol">
            <a-date-picker style="width: 100%" :showTime="{ format: 'YYYY-MM-DD HH:mm:ss' }"
                           format="YYYY-MM-DD HH:mm:ss"
                           v-decorator="['fromTime',{rules: [{ required: true, message: '上报时间不能为空' }]}]"
                           placeholder="请选择"></a-date-picker>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="清运日期"
                       :label-col="labelCol"
                       :wrapper-col="wrapperCol">
            <a-date-picker style="width:20%" :showTime="{ format: 'YYYY-MM-DD' }"
                           format="YYYY-MM-DD"
                           v-decorator="['latestDate',{rules: [{ required: true, message: '最晚清运时间不能为空' }]}]"
                           placeholder="请选择"></a-date-picker>
            <a-radio-group default-value="tomorrow" button-style="ghost" @change="dateChange" style="margin-left: 34px">
              <a-radio-button value="toDay">
                今天
              </a-radio-button>
              <a-radio-button value="tomorrow">
                明天
              </a-radio-button>
              <a-radio-button value="afterTomorrow">
                后天
              </a-radio-button>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="化粪池类型" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-radio-group
              v-decorator="['gatherSpotType', {initialValue: 'JIWUCHI',rules: [{ required: true, message: '请选择类型' }] }]" style="line-height: 2.5">
              <a-radio :value="spotTypeEnum.DANWENG.value">
                单翁
              </a-radio>
              <a-radio :value="spotTypeEnum.SANGE.value">
                三格化粪池
              </a-radio>
              <a-radio :value="spotTypeEnum.JIWUCHI.value">
                集污池
              </a-radio>
              <a-radio :value="spotTypeEnum.LANI.value">
                拉泥
              </a-radio>
              <a-radio :value="spotTypeEnum.GONGCE.value">
                公厕
              </a-radio>
              <a-radio :value="spotTypeEnum.OTHER.value">
                其他
              </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="是否紧急" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-radio-group
              v-decorator="['ifUrgent', {initialValue: 'false',rules: [{ required: true, message: '请选择是否紧急' }] }]">
              <a-radio value="true">
                紧急
              </a-radio>
              <a-radio value="false">
                不紧急
              </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="上报原因" :label-col="labelCol"
                       :wrapper-col="wrapperCol">
            <a-input
              v-decorator="['reason',{ rules: [{ required: true, message: '来电原因不能为空' }] }]"
              placeholder="请输入上报原因或者下方快捷点选"
            ></a-input>
            <a-radio-group @change="typeChange" button-style="ghost" size="small" style="line-height: 2.5; font-size: 12px">
              <a-radio-button value="JI">
                集污池已满
              </a-radio-button>
              <a-radio-button value="SAN" style="margin-left: 10px">
                三格化粪池已满
              </a-radio-button>
              <a-radio-button value="DAN" style="margin-left: 10px">
                单翁已满
              </a-radio-button>
              <a-radio-button value="NI" style="margin-left: 10px">
                泥已满
              </a-radio-button>
              <a-radio-button value="CE" style="margin-left: 10px">
                公厕已满
              </a-radio-button>
              <a-radio-button value="OTHER" style="margin-left: 10px">
                其他
              </a-radio-button>
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="车辆负责人"
                       :label-col="labelCol"
                       :wrapper-col="wrapperCol">
            <a-tree-select
              v-decorator="['dispatcherId',{validateTrigger: ['change'],rules: [{ required: true, message: '车辆负责人不能为空' }]}]"
              treeDefaultExpandAll
              treeNodeFilterProp="title"
              style="width: 100%"
              :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
              :treeData="orgUserTreeData"
              placeholder="请选择车辆负责人"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="调度指派:" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-checkbox-group v-model="dispatchType" @change="assignChange">
              <a-checkbox value="AUTO">
                自动调度
              </a-checkbox>
              <a-checkbox value="ASSIGN">
                指派司机
              </a-checkbox>
            </a-checkbox-group>
          </a-form-item>
          <a-form-item label="清运司机"
                       :label-col="labelCol"
                       :wrapper-col="wrapperCol" v-if="this.ifAssign === 'true' ">
            <a-tree-select
              v-decorator="['driverId',
                {
                  validateTrigger: ['change'],
                  rules: [{
                  required: true,
                    message: '请选择司机',
                  }]
                }
              ]"
              @select="driverSelect"
              treeDefaultExpandAll
              treeNodeFilterProp="title"
              style="width: 100%"
              :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
              :treeData="orgUserTreeData"
              placeholder="请选择司机"/>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="现场图片" :label-col="labelCol" :wrapper-col="wrapperCol">
            <qiniu-image-upload
              ref="qiniuImageRef"
              @getImageData="getImageData"
              :multiSelection="true"
              type="edit"
              :items="formItem.images"
            ></qiniu-image-upload>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <div style="margin-left: 30px;">
          <a-tag color="#6e79f5" style="margin: 10px;" v-for="item in reportRecordData" :key="item.id">{{item.latestDate}}</a-tag>
          <a-tag style="margin: 10px;" @click="showAllReportRecord" v-if="allReportRecordData.length>0">共{{allReportRecordData.length}}条</a-tag>
        </div>
      </a-row>
    </a-form>
  </a-spin>
</template>

<script>
  import { damageReportFormVO, reportTypeList } from './common/common'
  import SERVICE_URLS from '@/api/service.url'
  import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'
  import moment from 'moment'
  import { spotTypeEnum } from '@/constant/spot/spotType'

  export default {
    name: 'ReportForm',
    props: {
      type: {
        type: String,
        default: 'edit'
      }
    },
    components: { QiniuImageUpload },
    data () {
      return {
        reportTypeList: reportTypeList(),
        labelCol: { span: 5 },
        wrapperCol: { span: 17 },
        spinShow: false,
        formItem: damageReportFormVO(),
        form: this.$form.createForm(this),
        orgTree: [],
        orgUserTreeData: [],
        defaultDeptId: null,
        /*集污点检索*/
        searchContent: '',
        keySearchData: [], //自动完成检索后数据
        /*调度司机*/
        ifAssign: 'false',
        dispatchType: [],
        tempDriverId: null,
        reportRecordData: [],  //根据选中集污点搜素到的报抽记录 前12条记录
        allReportRecordData: [],  //根据选中集污点搜素到的报抽记录 所有记录
        showReportRecordSize: 13,
        spotTypeEnum: spotTypeEnum
      }
    },
    created () {
      this.loadUserList()
      this.getOrgTree()
    },
    mounted () {
      if (this.type === 'add') {
        this.form.setFieldsValue({
          fromTime: moment(new Date(), 'YYYY-MM-DD HH:mm:ss'),
          latestDate: moment(new Date(), 'YYYY-MM-DD').add(1, 'days')
        })
        this.setDeptId()
      }
    },
    methods: {
      /*调度面板上报*/
      findBySpotNoAndAssign (item) {
        this.setReportFields(item)
        this.formItem.reportRecord.spot.id = item.id
        this.addSetDispatchFields(item)
        this.form.setFieldsValue({
          fromTime: moment(new Date(), 'YYYY-MM-DD HH:mm:ss'),
          latestDate: moment(new Date(), 'YYYY-MM-DD').add(1, 'days')
        })
      },
      loadData (id) {
        this.formItem = damageReportFormVO()
        this.form.resetFields()
        this.$http(this, {
          url: SERVICE_URLS.csgz.reportRecord.view,
          loading: 'spinShow',
          params: {
            id: id
          },
          noTips: true,
          success: (data) => {
            this.setFields(data.body)
            this.setDispatchFields(data.body)
            setTimeout(() => {
              this.$nextTick(() => {
                this.tempDriverId = data.body.reportRecord.driverId
                const driverId = this.getSelectKey(this.orgUserTreeData, this.tempDriverId)
                if (driverId) {
                  this.form.setFieldsValue({
                    'driverId': driverId
                  })
                }
                const dispatcherId = this.getSelectKey(this.orgUserTreeData, data.body.reportRecord.dispatcherId)
                if (dispatcherId) {
                  this.form.setFieldsValue({
                    'dispatcherId': dispatcherId
                  })
                }
              })
            }, 200)
          }
        })
      },
      setFields (values) {
        Object.assign(this.formItem, values)
        values.images.forEach((item) => {
          this.formItem.imageIds.push(item.id)
        })
        this.form.setFieldsValue({
          fromName: this.formItem.reportRecord.fromName,
          fromPhone: this.formItem.reportRecord.fromPhone,
          fromAddress: this.formItem.reportRecord.spot.areaName,
          reason: this.formItem.reportRecord.reason,
          ifUrgent: this.formItem.reportRecord.ifUrgent + '',
          gatherSpotType: this.formItem.reportRecord.gatherSpotType,
          fromTime: this.formItem.reportRecord.fromTime,
          orgId: this.formItem.reportRecord.orgId + '',
          name: this.formItem.reportRecord.spot !== undefined ? this.formItem.reportRecord.spot.name : '',
          latestDate: this.formItem.reportRecord.latestDateTime
        })
      },
      retSetForm () {
        if (this.type === 'add') {
          this.formItem = damageReportFormVO()
          this.form = this.$form.createForm(this)
          this.searchContent = ''
          this.reportRecordData = []
          this.allReportRecordData = []
          this.keySearchData = []
          this.dispatchType = []
          this.ifAssign =  'false'
          this.form.setFieldsValue({
            fromTime: moment(new Date(), 'YYYY-MM-DD HH:mm:ss'),
            latestDate: moment(new Date(), 'YYYY-MM-DD').add(1, 'days')
          })
        }
      },
      loadUserList () {
        this.$http(this, {
          url: SERVICE_URLS.organization.orgUserTree,
          data: {
            authPrefix: '',
            chooseType: 'RADIO'
          },
          noTips: true,
          success: (data) => {
            this.orgUserTreeData = data.body
            /*  if (this.type === 'edit') {
                const driverId = this.getSelectKey(this.orgUserTreeData, this.tempDriverId)
                if (driverId) {
                  this.form.setFieldsValue({
                    'driverId': driverId
                  })
                }
              }*/
          }
        })
      },
      assignChange (checkedValues) {
        if (checkedValues.includes('AUTO') && !checkedValues.includes('ASSIGN')) {
          this.ifAssign = 'false'
          this.formItem.reportRecord.bindStatus = 'OPTIONAL'
          this.formItem.reportRecord.driverId = null
        }
        if (checkedValues.includes('ASSIGN')) {
          this.ifAssign = 'true'
          this.formItem.reportRecord.bindStatus = 'CHECKED'
        }
        if (!checkedValues.includes('ASSIGN') && !checkedValues.includes('AUTO')) {
          this.ifAssign = 'false'
          this.formItem.reportRecord.bindStatus = 'NOTOPEN'
          this.formItem.reportRecord.driverId = null
        }
      },
      onSelectSpotItem (id) {
        for (let i = 0; i < this.keySearchData.length; i++) {
          const item = this.keySearchData[i]
          if (item.id === Number(id)) {
            this.setReportFields(item)
            this.formItem.reportRecord.spot.id = item.id
            this.addSetDispatchFields(item)
            const dispatcherId = this.getSelectKey(this.orgUserTreeData, item.dispatcherId)
            this.form.setFieldsValue({
              fromTime: moment(new Date(), 'YYYY-MM-DD HH:mm:ss'),
              latestDate: moment(new Date(), 'YYYY-MM-DD').add(1, 'days'),
              'dispatcherId': dispatcherId
            })

            //根据选中集污点查询所有上报记录
            this.reportRecordData = []
            this.allReportRecordData = []
            this.getReportRecordList(id)
          }
        }
      },
      getReportRecordList (id) {
        this.$http(this, {
          url: SERVICE_URLS.csgz.reportRecord.list,
          loading: 'no',
          data: {
            spotId: id
          },
          noTips: true,
          success: (data) => {
            this.allReportRecordData = data.body
            if (data.body.length > this.showReportRecordSize) {
              this.reportRecordData = data.body.slice(0, this.showReportRecordSize)
            } else {
              this.reportRecordData = data.body
            }
          }
        })
      },
      searchClick () {
        if (this.searchContent.length > 1) {
          this.searchGatherSpotByKey(this.searchContent)
        } else {
          this.keySearchData = []
          this.$message.warning('关键字过少！')
        }
      },
      //登记集污点完成直接获取当前集污点信息并赋值到上报登记框
      findGatherSpot (gatherSpot) {
        setTimeout(() => {
          this.setReportFields(gatherSpot)
          this.formItem.reportRecord.spot.id = gatherSpot.id
          this.addSetDispatchFields(gatherSpot)
          const dispatcherId = this.getSelectKey(this.orgUserTreeData, gatherSpot.dispatcherId)
          this.form.setFieldsValue({
            fromTime: moment(new Date(), 'YYYY-MM-DD HH:mm:ss'),
            latestDate: moment(new Date(), 'YYYY-MM-DD').add(1, 'days'),
            'dispatcherId': dispatcherId
          })
        }, 200)
      },
      restForm () {
        this.form.resetFields()
        this.dispatchType = []
        this.ifAssign = 'false'
        this.form.setFieldsValue({
          fromTime: moment(new Date(), 'YYYY-MM-DD HH:mm:ss'),
          latestDate: moment(new Date(), 'YYYY-MM-DD').add(1, 'days')
        })
      },
      setDeptId () {
        this.defaultDeptId = this.currentDeptId().organizationId
        if (this.defaultDeptId) {
          this.form.setFieldsValue({
            'orgId': this.defaultDeptId
          })
        }
      },
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
          }
        })
      },
      getSelectKey (list, id) {
        var key
        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          const tempKey = item.key
          if (tempKey.substring('USER_') !== -1 && Number(tempKey.split('_')[2]) === id) {
            key = tempKey
            return key
          } else if (item.children.length > 0) {
            key = this.getSelectKey(item.children, id)
          }
          if (key) return key
        }
      },
      setVOFields (values) {
        Object.assign(this.formItem.reportRecord, values)
        if (values.driverId) {
          this.formItem.reportRecord.driverId = values.driverId.split('_')[2]
        }
        if (values.dispatcherId) {
          this.formItem.reportRecord.dispatcherId = values.dispatcherId.split('_')[2]
        }
        if (this.dispatchType.length === 0) {
          this.formItem.reportRecord.bindStatus = 'NOTOPEN'
        }
      },
      addSetDispatchFields (values) {
        if (values.driverId) {
          this.dispatchType = ['ASSIGN']
          this.ifAssign = 'true'
          this.$nextTick(() => {
            const driverId = this.getSelectKey(this.orgUserTreeData, values.driverId)
            if (driverId) {
              this.formItem.reportRecord.bindStatus = 'CHECKED'
              this.tempDriverId = driverId
              this.form.setFieldsValue({
                'driverId': driverId
              })
            }
          })
        }
      },
      setDispatchFields (values) {
        if (values.reportRecord.bindStatus === 'NOTOPEN') {
          this.dispatchType = []
          this.ifAssign = 'false'
        } else if (values.reportRecord.bindStatus === 'OPTIONAL') {
          this.dispatchType = ['AUTO']
          this.ifAssign = 'false'
        } else if (values.reportRecord.bindStatus === 'CHECKED') {
          this.dispatchType = ['AUTO', 'ASSIGN']
          this.ifAssign = 'true'
        } else {
          this.dispatchType = ['AUTO', 'ASSIGN']
          this.ifAssign = 'true'
        }
      },
      driverSelect (value) {
        this.tempDriverId = value.split('_')[2]
      },
      submitEdit () {
        if (this.ifAssign === 'true' && this.tempDriverId == null) {
          this.$message.warning('请选择司机！')
          return
        }
        this.form.validateFields((err, values) => {
          if (!err) {
            if (typeof this.setVOFields !== 'undefined' && this.setVOFields instanceof Function) {
              this.setVOFields(values)
            }
            this.$http(this, {
              url: SERVICE_URLS.csgz.reportRecord.update,
              data: this.formItem,
              loading: 'spinShow',
              params: {
                id: this.formItem.reportRecord.id
              },
              success: (data) => {
                this.dispatchType = []
                this.ifAssign = 'false'
                this.formItem = damageReportFormVO()
                this.$emit('editSuccess', data.body)
              }
            })
          } else {
            this.$emit('editError', 'formError')
          }
        })
      },
      submitAdd () {
        if (this.ifAssign === 'true' && this.tempDriverId == null) {
          this.$message.warning('请选择司机！')
          return
        }
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.csgz.reportRecord.register,
              noTips: true,
              loading: 'spinShow',
              data: this.formItem,
              success: (data) => {
                this.tempDriverId = null
                this.dispatchType = []
                this.searchContent = ''
                this.formItem = damageReportFormVO()
                this.ifAssign = 'false'
                this.form.resetFields()
                this.form.setFieldsValue({
                  fromTime: moment(new Date(), 'YYYY-MM-DD HH:mm:ss'),
                  latestDate: moment(new Date(), 'YYYY-MM-DD').add(1, 'days')
                })
                this.$emit('addSuccess')
              },
              error: (data) => {
              }
            })
          }
        })
      },
      searchGatherSpotByKey (key) {
        this.$http(this, {
          url: SERVICE_URLS.csgz.gatherSpot.findOneByKeyLike,
          data: { 'key': key, 'ifDataAuth': true },
          noTips: true,
          loading: 'spinShow',
          success: (data) => {
            this.$nextTick(() => {
              if (data.body.length) {
                this.keySearchData = data.body
              }
            })
          }, error: (data) => {
            this.keySearchData = []
          }
        })
      },
      loadOneGatherSpot (value) {
        this.$http(this, {
          url: SERVICE_URLS.csgz.gatherSpot.findOneByNo,
          data: { 'no': value },
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            this.$nextTick(() => {
              this.setReportFields(data.body)
              this.formItem.reportRecord.spot.id = data.body.id + ''
              this.addSetDispatchFields(data.body)
              this.form.setFieldsValue({
                fromTime: moment(new Date(), 'YYYY-MM-DD HH:mm:ss'),
                latestDate: moment(new Date(), 'YYYY-MM-DD').add(1, 'days')
              })
            })
          }, error: (data) => {
          }
        })
      },
      setReportFields (value) {
        this.form.setFieldsValue({
          fromName: value.userName,
          fromPhone: value.userPhone,
          fromAddress: value.areaName,
          reason: (value.type === spotTypeEnum.DANWENG.value ? '单翁' : value.type === spotTypeEnum.SANGE.value ? '三格化粪池' : '集污池') + '已满，需清运。',
          gatherSpotType: value.type,
          fromTime: moment(new Date(), 'YYYY-MM-DD'),
          'orgId': value.orgId + '',
          name: value.name
        })
      },
      dateChange (e) {
        if (e.target.value === 'toDay') {
          this.form.setFieldsValue({
            latestDate: moment(new Date(), 'YYYY-MM-DD')
          })
        } else if (e.target.value === 'tomorrow') {
          this.form.setFieldsValue({
            latestDate: moment(new Date(), 'YYYY-MM-DD').add(1, 'days')
          })
        } else {
          this.form.setFieldsValue({
            latestDate: moment(new Date(), 'YYYY-MM-DD').add(2, 'days')
          })
        }
      },
      typeChange (e) {
        if (e.target.value === 'JI') {
          this.form.setFieldsValue({
            reason: '集污池已满，需清运。'
          })
        } else if (e.target.value === 'SAN') {
          this.form.setFieldsValue({
            reason: '三格化粪池已满，需清运。'
          })
        } else if (e.target.value === 'DAN') {
          this.form.setFieldsValue({
            reason: '单翁已满，需清运。'
          })
        } else if (e.target.value === 'NI') {
          this.form.setFieldsValue({
            reason: '泥已满，需清运。'
          })
        } else if (e.target.value === 'CE') {
          this.form.setFieldsValue({
            reason: '公厕已满，需清运。'
          })
        } else {
          this.form.setFieldsValue({
            reason: '其他集污点，需清运。'
          })
        }
      },
      /*上传图片回调方法*/
      getImageData (data) {
        this.formItem.imageIds = []
        data.forEach(item => {
          this.formItem.imageIds.push(item.id)
        })
      },
      showAllReportRecord () {
        if (this.allReportRecordData.length > this.showReportRecordSize) {
          if (this.allReportRecordData.length === this.reportRecordData.length) {
            this.reportRecordData = this.allReportRecordData.slice(0, this.showReportRecordSize)
          } else {
            this.reportRecordData = this.allReportRecordData
          }
        }

      }
    }
  }
</script>

<style scoped></style>
